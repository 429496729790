import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { useProfile } from '../../../UserDataStock/Profils/Profile';
import { AssureVoyageModificationValidation} from '../../../utils/FormValidation';
import FormatMontant from '../../../utils/FormatMontant';
import moment from 'moment';
import { BsBuildingsFill, BsPassportFill } from 'react-icons/bs';
import Select from 'react-select';
import { Pays } from '../../../utils/pays.json';
import { FaCheckCircle, FaEnvelope, FaFileContract, FaRegCheckCircle } from 'react-icons/fa';
import Datepicker from 'react-tailwindcss-datepicker';
import { FaUserPen, FaUserTie  } from 'react-icons/fa6';
import { MdDeleteForever } from 'react-icons/md';
import { createRoot } from 'react-dom/client';
import { ImManWoman } from 'react-icons/im';
import PhoneInput from 'react-phone-input-2';
import { MdPlace, MdWork } from 'react-icons/md';
import { TbNumber, TbSwitchHorizontal } from 'react-icons/tb';
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { succesNotification } from '../../notifications/Notifications';
import { AnimatePresence, motion } from 'framer-motion';
import { getTarifVoyage, getTarifVoyageGn } from '../../../api/voyages/TarifAndFormulesVoy';
import { Spinner } from 'keep-react';




const EditContrat = ({ contractData,  onUpdate , chargement }) => {
    const { position, token, userDetails } = useProfile();
    const [tarification, setTarification] = useState([]);
    const [loading, setLoading] = useState(false);
    const [today, setToday] = useState(moment().format('YYYY-MM-DD'));
    const [collapsedVoyageurs, setCollapsedVoyageurs] = useState({});
    const [devis, setDevis] = useState('');
    const [selectedSubscriber, setSelectedSubscriber] = useState(null);
    const ref = useRef(null);
    const previousValues = useRef({
      tarification: null,
      voyageurs: null
    });

    useEffect(() => {
      if(position?.location?.country_code3 === "GIN"){
        setDevis("GNF")
      }else{
        setDevis("XOF")
      }
    }, []);


    const formik = useFormik({
      initialValues: {
        voyage: {
          dateDepart: '',
          dateRetour: '',
          departId: 0,
          destinationId: 0,
          voyageurs: [
            {
              isSubscriber: false,
              nom: '',
              prenom: '',
              genre: '',
              adresse: '',
              dateNaissance: '',
              tel: '',
              email: '',
              primeTTC: '',
              passport: '',
              dateDelivrance: '',
              dateExp: '',
            },
          ],
        },
        contrat: {
          duree: 0,
          pointDeVenteId: '',
          dateDebut: '',
          assureurId: '',
          formuleId: 0,
        },
        details: {
          primeNette: 0,
          coutPolice: 0,
          taxes: 0,
          primeTTC: 0,
          prixConseille: 0,
          montantTotal: 0,
        },
        souscripteur: {
          type: '',
          genre: null,
          nom: '',
          prenom: '',
          raison: '',
          domaine: '',
          adresse: '',
          tel: '',
          email: '',
        },
      },
      validate: AssureVoyageModificationValidation,
      onSubmit: (values) => {
        // Calculer la durée
        const durees = moment(values.voyage.dateRetour).diff(moment(values.voyage.dateDepart), 'days');
        
        const submitValues = {
          ...values,
          contrat: {
            ...values.contrat,
            duree: durees 
          },
          details: {
            primeNette: tarification?.formules?.[0]?.primeTTCTotal || 0,
            coutPolice: tarification?.formules?.[0]?.policeTotal || 0,
            taxes: tarification?.formules?.[0]?.taxesTotal || 0,
            primeTTC: tarification?.formules?.[0]?.primeTTCTotal || 0,
            prixConseille: tarification?.formules?.[0]?.prixConseilleTotal || 0,
            montantTotal: tarification?.formules?.[0]?.primeTTCTotal || 0
          }
        };
        onUpdate(submitValues);
      },
    });
  
    useEffect(() => {
      if (tarification) {
        formik.setFieldValue('details', {
          primeNette: tarification?.formules?.[0]?.primeTTCTotal || 0,
          coutPolice: tarification?.formules?.[0]?.policeTotal || 0,
          taxes: tarification?.formules?.[0]?.taxesTotal || 0,
          primeTTC: tarification?.formules?.[0]?.primeTTCTotal || 0,
          prixConseille: tarification?.formules?.[0]?.prixConseilleTotal || 0,
          montantTotal: tarification?.formules?.[0]?.primeTTCTotal || 0
        });
      }
    }, [tarification]);
    
    useEffect(() => {
      // Vérifier si les données ont réellement changé
      const tarificationChanged = JSON.stringify(previousValues.current.tarification) !== JSON.stringify(tarification);
      const voyageursChanged = JSON.stringify(previousValues.current.voyageurs) !== JSON.stringify(formik.values.voyage.voyageurs);

      if (!tarificationChanged && !voyageursChanged) {
        return;
      }

      try {
        if (tarification?.formules?.[0]?.detailsParVoyageur && formik.values.voyage.voyageurs) {
          const updatedVoyageurs = formik.values.voyage.voyageurs.map(voyageur => {
            if (!voyageur.dateNaissance) return voyageur;

            const age = moment().diff(moment(voyageur.dateNaissance), 'years');
            const matchingDetail = tarification.formules[0].detailsParVoyageur.find(
              detail => detail.age === age
            );

            return {
              ...voyageur,
              primeTTC: matchingDetail ? matchingDetail.primeTTC : 0
            };
          });

          formik.setFieldValue('voyage.voyageurs', updatedVoyageurs, false);
        }

        // Mettre à jour les références
        previousValues.current = {
          tarification,
          voyageurs: formik.values.voyage.voyageurs
        };
      } catch (error) {
        console.error('Erreur lors de la mise à jour des primeTTC:', error);
      }
    }, [tarification, formik.values.voyage.voyageurs]);
  
    const {
      assureur = {},
      contrat = {},
      formule = {},
      pointDeVente = {},
      souscripteur = {},
      voyage = {},
    } = contractData;
    // console.log(souscripteur);

    useEffect(() => {
      if (!contractData) return;
  
      const {
        assureur = {},
        contrat = {},
        formule = {},
        pointDeVente = {},
        souscripteur = {},
        voyage = {},
      } = contractData;
      // console.log(contractData);
  
      formik.setValues({
        voyage: {
          dateDepart: voyage?.dateDepart || '',
          dateRetour: voyage?.dateRetour || '',
          departId: voyage?.departId || 0,
          destinationId: voyage?.destinationId || 0,
          voyageurs: voyage?.Voyageurs?.map((v) => ({
            isSubscriber: v.id === souscripteur?.id,
            nom: v.nom || '',
            prenom: v.prenom || '',
            genre: v.genre || '',
            adresse: v.adresse || '',
            dateNaissance: v.dateNaissance || '',
            tel: v.telephone || '',
            email: v.email || '',
            primeTTC: v.primeTTC || '',
            passport: v.numPassport || '',
            dateExp: v.dateExpiration || '',
            dateDelivrance: v.dateDelivrance || '',
          })) || [],
        },
        contrat: {
          duree: contrat?.duree || 0,
          pointDeVenteId: contrat?.pointDeVenteId || '',
          dateDebut: contrat?.DetailsContrats?.[0]?.dateDebut || '',
          assureurId: contrat?.assureurId || '',
          formuleId: contrat?.formuleId || 0,
        },
        details: {
          primeNette: contrat?.DetailsContrats?.[0]?.primeNette || 0,
          coutPolice: contrat?.DetailsContrats?.[0]?.accessoire || 0,
          taxes: contrat?.DetailsContrats?.[0]?.taxes || 0,
          primeTTC: contrat?.DetailsContrats?.[0]?.primeTTC || 0,
          prixConseille: contrat?.DetailsContrats?.[0]?.fraisDeServices || 0,
          montantTotal: contrat?.DetailsContrats?.[0]?.montantTotal || 0,
        },
        souscripteur: {
          type: souscripteur?.type || 'particulier',
          genre: souscripteur?.genre || '',
          nom: souscripteur?.nom || '',
          prenom: souscripteur?.prenom || '',
          raison: souscripteur?.raisonSociale || '',
          domaine: souscripteur?.domaine || '',
          adresse: souscripteur?.adresse || '',
          tel: souscripteur?.telephone || '',
          email: souscripteur?.email || '',
        },
      });
    }, [contractData]);

    const paysOptions = Pays.map(pays => ({
      value: pays.id,
      label: pays.nom
    }));

    const handleDateDepartChange = (newValue) => {
      formik.setFieldValue('voyage.dateDepart', newValue?.startDate ? moment(newValue.startDate).format('YYYY-MM-DD') : '');
    };
    
    const handleDateRetourChange = (newValue) => {
      formik.setFieldValue('voyage.dateRetour', newValue?.startDate ? moment(newValue.startDate).format('YYYY-MM-DD') : '');
    };

    const addVoyageur = () => {
      formik.setFieldValue('voyage.voyageurs', [
        ...formik.values.voyage.voyageurs,
        {
          isSubscriber: false,
          nom: '',
          prenom: '',
          genre: null,
          dateNaissance: '',
          tel: '',
          email: '',
          passport: '',
          adresse: '',
          dateExp: '',
          dateDelivrance: '',
        }
      ]);
      succesNotification("Un nouveau formulaire d'assuré a été ajouté avec succès. Veuillez le remplir !!!")
    };

    const toggleVoyageur = (index) => {
      setCollapsedVoyageurs(prev => ({
        ...prev,
        [index]: !prev[index]
      }));
    };
    
    const removeVoyageur = (index) => {
      const newVoyageurs = [...formik.values.voyage.voyageurs];
      newVoyageurs.splice(index, 1);
      formik.setFieldValue('voyage.voyageurs', newVoyageurs);
    
      // Mettre à jour l'état des voyageurs repliés
      const newCollapsedVoyageurs = { ...collapsedVoyageurs };
      delete newCollapsedVoyageurs[index];
      setCollapsedVoyageurs(newCollapsedVoyageurs);
    };

    // Ajouter cette fonction dans EditContrat.jsx
    const isFormValid = (voyager) => {
      const requiredFields = [
        'prenom', 'nom', 'genre', 'dateNaissance', 'tel', 
        'email', 'passport', 'dateExp', 'adresse'
      ];
      return requiredFields.every(field => voyager[field]);
    };

    const handleSelectSouscripteur = (index) => {
      const selectedVoyager = formik.values.voyage.voyageurs[index];
      setSelectedSubscriber(index);
    
      // Update isSubscriber for all voyageurs
      const updatedVoyageurs = formik.values.voyage.voyageurs.map((voyager, i) => ({
        ...voyager,
        isSubscriber: i === index
      }));
      formik.setFieldValue('voyage.voyageurs', updatedVoyageurs);
      
      // Create alert component
      const AlertComponent = () => (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              root.unmount();
              document.body.removeChild(alertContainer);
            }
          }}
        >
          <motion.div
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", damping: 15, stiffness: 200 }}
            className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4"
          >
            <div className="text-center">
              <motion.div 
                className="flex justify-center mb-4"
                initial={{ rotate: -180, scale: 0 }}
                animate={{ rotate: 0, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <FaUserTie className="text-purple-500 text-4xl" />
              </motion.div>
              <motion.h3 
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="text-lg font-semibold text-gray-900 mb-2"
              >
                L'assuré est le Souscripteur
              </motion.h3>
              <motion.p 
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-gray-600 mb-6"
              >
                L'assuré <span className="font-bold text-purple-600">{selectedVoyager.prenom} {selectedVoyager.nom}</span> est considéré(e) comme étant le souscripteur 
              </motion.p>
              <motion.button
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-purple-500 text-white px-6 py-2 rounded-lg hover:bg-purple-600 transition-colors"
                onClick={() => document.body.removeChild(document.getElementById('alert-container'))}
              >
                OK
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      );
    
      // Create container for alert
      const alertContainer = document.createElement('div');
      alertContainer.id = 'alert-container';
      document.body.appendChild(alertContainer);
    
      // Create React root and render component
      const root = createRoot(alertContainer);
      root.render(<AlertComponent />);
    
      // Update souscripteur form values
      formik.setFieldValue('souscripteur', {
        type: 'particulier',
        genre: selectedVoyager.genre,
        nom: selectedVoyager.nom,
        prenom: selectedVoyager.prenom,
        // raison: '',
        // // profession: '',
        // domaine: '',
        adresse: selectedVoyager.adresse,
        tel: selectedVoyager.tel,
        email: selectedVoyager.email,
      });
    };

    const handleUnselectSouscripteur = () => {
      // Create alert component for unselect confirmation
      const AlertComponent = () => (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              root.unmount();
              document.body.removeChild(alertContainer);
            }
          }}
        >
          <motion.div
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", damping: 15, stiffness: 200 }}
            className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4"
          >
            <div className="text-center">
              <motion.div 
                className="flex justify-center mb-4"
                initial={{ rotate: -180, scale: 0 }}
                animate={{ rotate: 0, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <MdDeleteForever className="text-red-500 text-4xl" />
              </motion.div>
              <motion.h3 
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="text-lg font-semibold text-gray-900 mb-2"
              >
                Suppression du souscripteur actuel
              </motion.h3>
              <motion.p 
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-gray-600 mb-6"
              >
                Les données de l’assuré(e) devenue souscripteur ont été retiré avec succès 
              </motion.p>
              <motion.button
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-colors"
                onClick={() => document.body.removeChild(document.getElementById('alert-container'))}
              >
                OK
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      );
    
      // Create container and render alert
      const alertContainer = document.createElement('div');
      alertContainer.id = 'alert-container';
      document.body.appendChild(alertContainer);
      const root = createRoot(alertContainer);
      root.render(<AlertComponent />);
    
      // Reset subscriber selection
      const updatedVoyageurs = formik.values.voyage.voyageurs.map(voyager => ({
        ...voyager,
        isSubscriber: false
      }));
      formik.setFieldValue('voyage.voyageurs', updatedVoyageurs);
      setSelectedSubscriber(null);
      
      // Reset souscripteur form
      formik.setFieldValue('souscripteur', {
        type: '',
        genre: null,
        nom: '',
        prenom: '',
        raison: '',
        domaine: '',
        adresse: '',
        tel: '',
        email: '',
      });
    };

    async function getTarif(data) {
      if (!data || !token || !position) {
        console.error('Données manquantes pour la récupération des tarifs');
        return;
      }
    
      setLoading(true);
      
      const donnes = {
        destination: data.destinationId,
        duree: data.duree,
        agesArray: data.ages,
      };
    
      try {
        let tarifFunction;
        if (position?.location?.country_code3 === "GIN") {
          tarifFunction = getTarifVoyageGn;
          setDevis("GNF");
        } else {
          tarifFunction = getTarifVoyage;
          setDevis("XOF");
        }
    
        const result = await tarifFunction(donnes, token, position);
        
        if (result?.status === 200) {
          const assureurTarif = result.data?.find(t => t.assurance.id === contrat.assureurId);
          if (assureurTarif) {
            setTarification(assureurTarif);
          }
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des tarifs:', error.message);
      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      // Vérifier si les valeurs pertinentes ont réellement changé
      const shouldUpdateTarif = (prev, current) => {
        return (
          prev.dateDepart !== current.dateDepart ||
          prev.dateRetour !== current.dateRetour ||
          prev.destinationId !== current.destinationId ||
          JSON.stringify(prev.voyageurs.map(v => v.dateNaissance)) !== 
          JSON.stringify(current.voyageurs.map(v => v.dateNaissance))
        );
      };
    
      if (formik.values.voyage?.dateDepart && formik.values.voyage?.dateRetour) {
        const duree = moment(formik.values.voyage.dateRetour).diff(moment(formik.values.voyage.dateDepart), 'days');
        formik.setFieldValue('contrat.duree', duree);
        
        // Calculer les âges des voyageurs
        const ages = formik.values.voyage.voyageurs.map(v => 
          moment().diff(moment(v.dateNaissance), 'years')
        );
    
        // Récupérer les tarifs seulement si les valeurs pertinentes ont changé
        if (formik.values.voyage.destinationId && duree && ages.length > 0) {
          const currentValues = {
            dateDepart: formik.values.voyage.dateDepart,
            dateRetour: formik.values.voyage.dateRetour,
            destinationId: formik.values.voyage.destinationId,
            voyageurs: formik.values.voyage.voyageurs
          };
    
          // Utiliser une référence pour stocker les valeurs précédentes
          if (!ref.current || shouldUpdateTarif(ref.current, currentValues)) {
            getTarif({
              destinationId: formik.values.voyage.destinationId,
              duree,
              ages
            });
            ref.current = currentValues;
          }
        }
      }
    }, [
      formik.values.voyage?.dateDepart,
      formik.values.voyage?.dateRetour,
      formik.values.voyage?.destinationId,
      formik.values.voyage.voyageurs
    ]);

    

    const getFieldError = (field, index) => {
      const errorKey = `voyage.voyageurs[${index}].${field}`;
      return formik.errors[errorKey] && formik.touched.voyage?.voyageurs?.[index]?.[field];
    };

    const isFormVOK = () => {
      // Vérifier si le formulaire a des erreurs
      if (Object.keys(formik.errors).length > 0) return false;
    
      // Vérifier les champs du souscripteur
      const souscripteur = formik.values.souscripteur;
      if (!souscripteur.type || !souscripteur.tel || !souscripteur.email || !souscripteur.adresse) {
        return false;
      }
    
      // Vérifications supplémentaires selon le type de souscripteur
      if (souscripteur.type === 'particulier') {
        if (!souscripteur.genre || !souscripteur.nom || !souscripteur.prenom) {
          return false;
        }
      } else if (souscripteur.type === 'entreprise') {
        if (!souscripteur.raison) {
          return false;
        }
      }
    
      // Vérifier les champs des voyageurs
      return formik.values.voyage.voyageurs.every(voyageur => {
        return (
          voyageur.prenom &&
          voyageur.nom &&
          voyageur.genre &&
          voyageur.dateNaissance &&
          voyageur.tel &&
          voyageur.email &&
          voyageur.passport &&
          voyageur.dateExp &&
          voyageur.dateDelivrance &&
          voyageur.adresse
        );
      });
    };

    const isContratModifiable = () => {
      const departDate = moment(formik.values.voyage.dateDepart).startOf('day');
      const aujourdhui = moment().startOf('day');
      return departDate.isAfter(aujourdhui);
    };
  
    return (
      <div className='container mx-auto py-4 md:py-8'>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-2 md:gap-4'>
          <div className='col-span-1 lg:col-span-2 bg-[#e3e9ff] p-4 md:p-6 lg:p-8 rounded-xl border shadow-md'>
            
            <form onSubmit={formik.handleSubmit} className='h-full flex flex-col'>
              <div className="flex-grow relative">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-12 gap-4 md:gap-6 lg:gap-8 py-4">
                  <div className='col-span-12 sm:col-span-12 md:col-span-12 lg:col</div>-span-12'>
                    <h1 className='font-bold text-lg text-white p-2 bg-purple-600  '>Les Informations du Contrat</h1>
                  </div>
                  <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-3 z-0'>
                    <label className="block text-gray-700 font-semibold mb-1 pl-2">Police N°</label>
                    <div className='relative'>
                      <TbNumber className='absolute left-3 top-3 text-gray-400'/>
                    </div>
                    <input 
                      disabled
                      type='text'
                      name='contrat.ref'
                      value={contrat.edited ? `${contrat.ref}/${contrat.edited}` : contrat.ref}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder='Numéro du contrat'
                      className="pl-10 pr-4 py-2 border bg-lime-100 border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-9 z-0'>
                      <label className="block text-gray-700 font-semibold mb-1 pl-2">Formule Choisie</label>
                      <div className='relative'>
                        <FaFileContract className='absolute left-3 top-3 text-gray-400'/>
                      </div>
                      <input 
                        disabled
                        type='text'
                        name='formule.name'
                        value={formule.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder='Formue Choisie'
                        className="pl-10 pr-4 py-2 border bg-lime-100 border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 "
                      />
                  </div>

                  <div className='col-span-12 sm:col-span-12 md:col-span-12 lg:col</div>-span-12'>
                    <h1 className='font-bold text-lg text-white p-2 bg-purple-600  '>Les Informations du Voyage</h1>
                  </div>
                  
                  <div className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0">
                    <label htmlFor="departId" className="block text-gray-700 font-semibold mb-1 pl-2">
                      Pays de résidence
                    </label>
                    <div className="relative select-container">
                    <Select
                        isDisabled
                        id="voyage.departId"
                        name="voyage.departId"
                        options={paysOptions}
                        value={paysOptions.find(option => parseInt(option.value) === parseInt(formik.values.voyage.departId))}
                        onChange={(selectedOption) => {
                          formik.setFieldValue('voyage.departId', selectedOption ? parseInt(selectedOption.value) : '')
                        }}
                        onBlur={formik.handleBlur}
                        placeholder="Sélectionnez un pays"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable
                      />
                    </div>
                  </div>

                  <div className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0">
                    <label htmlFor="destinationId" className="block text-gray-700 font-semibold mb-1 pl-2">
                      Pays de destination
                    </label>
                    <div className="relative select-container">
                      <Select
                          isDisabled
                          id="voyage.destinationId"
                          name="voyage.destinationId"
                          options={paysOptions}
                          value={paysOptions.find(option => parseInt(option.value) === parseInt(formik.values.voyage.destinationId))}
                          onChange={(selectedOption) => {
                            formik.setFieldValue('voyage.destinationId', selectedOption ? parseInt(selectedOption.value) : '')
                          }}
                          onBlur={formik.handleBlur}
                          placeholder="Sélectionnez un pays"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isClearable
                        />
                    </div>
                  </div>
                  
                <div className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0">
                    <label className="block text-gray-700 font-semibold mb-1 pl-2">
                      Date de départ
                    </label>
                    <Datepicker
                      disabled={!isContratModifiable()}
                      i18n={"fr"}
                      useRange={false}
                      asSingle={true}
                      readOnly={true}
                      inputClassName={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-5 py-2 cursor-pointer border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                        formik.errors.voyage?.dateDepart && formik.touched.voyage?.dateDepart ? 'border-red-500' : 'border-gray-300'
                      }`}
                      value={formik.values.voyage.dateDepart ? { 
                        startDate: formik.values.voyage.dateDepart, 
                        endDate: formik.values.voyage.dateDepart 
                      } : null}
                      onChange={handleDateDepartChange}
                      onBlur={() => formik.setFieldTouched('voyage.dateDepart', true)}
                      minDate={new Date(today)}
                      displayFormat={"DD/MM/YYYY"}
                      placeholder="Sélectionner une date"
                      popoverDirection='up'
                    />
                    {formik.errors.voyage?.dateDepart && formik.touched.voyage?.dateDepart && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500 mt-1"
                      >
                        {formik.errors.voyage.dateDepart}
                      </motion.div>
                    )}
                  </div>
                  
                  <div className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0">
                    <label className="block text-gray-700 font-semibold mb-1 pl-2">
                      Date de retour
                    </label>
                    <Datepicker
                      disabled={!isContratModifiable()}
                      i18n={"fr"}
                      useRange={false}
                      asSingle={true}
                      readOnly={true}
                      inputClassName={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-5 py-2 cursor-pointer border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                        formik.errors.voyage?.dateRetour && formik.touched.voyage?.dateRetour ? 'border-red-500' : 'border-gray-300'
                      }`}
                      value={formik.values.voyage.dateRetour ? { 
                        startDate: formik.values.voyage.dateRetour, 
                        endDate: formik.values.voyage.dateRetour 
                      } : null}
                      onChange={handleDateRetourChange}
                      onBlur={() => formik.setFieldTouched('voyage.dateRetour', true)}
                      minDate={formik.values.voyage.dateDepart ? moment(formik.values.voyage.dateDepart).add(2, 'days').toDate() : new Date(today)}
                      displayFormat={"DD/MM/YYYY"}
                      placeholder="Sélectionner une date"
                      popoverDirection='up'
                    />
                    {formik.errors.voyage?.dateRetour && formik.touched.voyage?.dateRetour && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500 mt-1"
                      >
                        {formik.errors.voyage.dateRetour}
                      </motion.div>
                    )}
                  </div>

                  <div className='col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 '>
                    <div className='flex justify-between items-center'>
                        <h1 className='font-bold text-lg text-white p-2 bg-purple-600'>La Liste des Assurés</h1>
                        <div className={`flex items-center gap-2 ${
                            isContratModifiable() 
                              ? 'cursor-pointer bg-purple-600 hover:bg-purple-700' 
                              : 'cursor-not-allowed bg-gray-400'
                          } p-2 text-white rounded-lg font-semibold`}
                          // onClick={() => isContratModifiable() && addVoyageur()}
                          onClick={() => alert("Bientot disponible")}
                        >
                            <AiOutlineUsergroupAdd size={18} className='font-semibold' /> 
                            <span >Ajouter un assuré</span>
                        </div>
                    </div>
                  </div>
                  
                  <AnimatePresence>
                  {formik.values.voyage.voyageurs.map((voyageur, index) => (
                    
                      <motion.div 
                        key={index}
                        initial={{ opacity: 0, height: 0, y: -20 }}
                        animate={{ opacity: 1, height: "auto", y: 0 }}
                        exit={{ opacity: 0, height: 0, y: -30, marginBottom: 0 }}
                        transition={{ 
                          duration: 0.3,
                          height: { duration: 0.2 },
                          opacity: { duration: 0.2 }
                        }} 
                        className="col-span-12 bg-gray-50 rounded-lg p-5 mb-4 border-teal-400 border"
                      >
                        <div className='flex justify-between items-center mb-4'>
                          <div className="flex items-center space-x-2">
                            <h3 className='font-semibold text-gray-700 text-lg underline underline-offset-2'>Assuré {index + 1}</h3>
                            <button 
                              type="button"
                              onClick={() => toggleVoyageur(index)}
                              className="p-2 hover:bg-gray-200 rounded-full transition-colors"
                            >
                              {collapsedVoyageurs[index] ? <IoIosArrowDown /> : <IoIosArrowUp />}
                            </button>
                          </div>

                          {/* Ajout des boutons souscripteur */}
                          <div className="flex items-center space-x-4">
                            {voyageur.isSubscriber && (
                              <div className='flex flex-row space-x-2 text-green-600 justify-center items-center bg-green-100 rounded-md p-2'>
                                <FaUserTie size={18} />
                                <span className='font-bold text-md'>Souscripteur actuel</span>
                              </div>
                            )}
                            {!voyageur.isSubscriber && (
                              <div 
                                className={`flex flex-row space-x-2 text-purple-600 justify-center items-center bg-purple-100 rounded-md p-2 
                                  ${isFormValid(formik.values.voyage.voyageurs[index]) 
                                    ? 'cursor-pointer hover:bg-purple-600 hover:text-white' 
                                    : 'opacity-50 cursor-not-allowed'}`}
                                onClick={() => isFormValid(formik.values.voyage.voyageurs[index]) && handleSelectSouscripteur(index)}
                              >
                                <FaUserTie size={18} />
                                <span className='font-bold text-md'>
                                  {isFormValid(formik.values.voyage.voyageurs[index]) 
                                    ? 'Souscripteur' 
                                    : 'Complétez le formulaire'}
                                </span>
                              </div>
                            )}

                            {/* pour la suppression d'un assure */}
                            {/* {index > 0 && (
                              <button 
                                type="button"
                                onClick={() => removeVoyageur(index)}
                                className="px-3 py-1 bg-red-100 text-red-600 rounded-md hover:bg-red-200 transition-colors"
                              >
                                Supprimer
                              </button>
                            )} */}
                          </div>
                        </div>

                        <AnimatePresence mode='sync'>
                        {!collapsedVoyageurs[index] && (
                          <motion.div 
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                            className="grid grid-cols-12 gap-4 ">
                            {/* Prénom */}
                            <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0'>
                              <label className="block text-gray-700 font-semibold mb-1 pl-2">Prénom</label>
                              <div className='relative '>
                                <FaUserPen className='absolute left-3 top-3 text-gray-400'/>
                              </div>
                              <input 
                                type='text'
                                disabled={!isContratModifiable()}
                                name={`voyage.voyageurs.${index}.prenom`}
                                value={voyageur.prenom}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder='Prénom'
                                className={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                  getFieldError('prenom', index) ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                                }`}
                              />
                              {getFieldError('prenom', index) && (
                                <motion.div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  transition={{ duration: 0.5 }}
                                  className="italic text-md text-red-500 mt-1"
                                >
                                  {formik.errors[`voyage.voyageurs[${index}].prenom`]}
                                </motion.div>
                              )}
                            </div>
                            
                            {/* Nom */}
                            <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0'>
                              <label className="block text-gray-700 font-semibold mb-1 pl-2">Nom</label>
                              <div className='relative'>
                                <FaUserPen className='absolute left-3 top-3 text-gray-400'/>
                              </div>
                              <input 
                                  disabled={!isContratModifiable()}
                                  type='text'
                                  name={`voyage.voyageurs.${index}.nom`}
                                  value={voyageur.nom}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder='Nom'
                                  className={` ${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    getFieldError('nom', index) ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                                  }`}
                                />
                                {getFieldError('nom', index) && (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    className="italic text-md text-red-500 mt-1"
                                  >
                                    {formik.errors[`voyage.voyageurs[${index}].nom`]}
                                  </motion.div>
                                )}
                            </div>

                            {/* Genre  */}
                            <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0'>
                              <label className='block text-gray-700 font-semibold mb-1 pl-2'>Genre</label>
                              <div className='relative '>
                                <ImManWoman className="absolute left-3 top-3 text-gray-400" />
                              </div>
                              <select
                                disabled={!isContratModifiable()}
                                name={`voyage.voyageurs.${index}.genre`}
                                value={voyageur.genre}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                  getFieldError('genre', index) ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                                }`}
                              >
                                <option value="">Sélectionnez</option>
                                <option value="Monsieur">Monsieur</option>
                                <option value="Madame">Madame</option>
                              </select>
                              {getFieldError('genre', index) && (
                                <motion.div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  transition={{ duration: 0.5 }}
                                  className="italic text-md text-red-500 mt-1"
                                >
                                  {formik.errors[`voyage.voyageurs[${index}].genre`]}
                                </motion.div>
                              )}
                            </div>

                            {/* Telephone */}
                            <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-10'>
                              <label className="block text-gray-700 font-semibold mb-1 pl-2">Téléphone</label>
                              <PhoneInput
                                country={'sn'}
                                onlyCountries={['sn', 'gn', 'ml', 'ci']}
                                masks={{ 
                                  sn: '-..-...-..-..',
                                  gn: '-..-...-...',
                                  ml: '-..-...-...',
                                  ci: '-..-..-..-..'
                                }}
                                disabled={!isContratModifiable()} // Ajout de disabled
                                value={voyageur.tel}
                                onChange={(value) => formik.setFieldValue(`voyage.voyageurs.${index}.tel`, value)}
                                containerClass="relative"
                                inputStyle={{
                                  width: '100%',
                                  height: '2.5rem',
                                  paddingLeft: '3rem',
                                  paddingRight: '1rem',
                                  borderRadius: '0.5rem',
                                  backgroundColor: !isContratModifiable() ? '#ecfccb' : 'white', // Ajout du background
                                  border: getFieldError('tel', index) ? '1px solid #EF4444' : '1px solid #E5E7EB'
                                }}
                                inputProps={{
                                  className: 'focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent z-10'
                                }}
                              />
                              {getFieldError('tel', index) && (
                                <motion.div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  transition={{ duration: 0.5 }}
                                  className="italic text-md text-red-500 mt-1"
                                >
                                  {formik.errors[`voyage.voyageurs[${index}].tel`]}
                                </motion.div>
                              )}
                            </div>
                            
                            {/* date de naissance */}
                            <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0'>
                              <label className="block text-gray-700 font-semibold mb-1 pl-2">Date de naissance</label>
                              <Datepicker
                                disabled={!isContratModifiable()}
                                i18n={"fr"}
                                useRange={false}
                                asSingle={true}
                                readOnly={true}
                                inputClassName={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 cursor-pointer border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                  getFieldError('dateNaissance', index) ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                                }`}
                                value={voyageur.dateNaissance ? { startDate: voyageur.dateNaissance, endDate: voyageur.dateNaissance } : null}
                                onChange={(newValue) => {
                                  formik.setFieldValue(`voyage.voyageurs.${index}.dateNaissance`, newValue?.startDate ? moment(newValue.startDate).format('YYYY-MM-DD') : '');
                                }}
                                maxDate={new Date(today)}
                                displayFormat={"DD/MM/YYYY"}
                                placeholder="Sélectionner une date"
                                popoverDirection='up'
                              />
                              {getFieldError('dateNaissance', index) && (
                                <motion.div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  transition={{ duration: 0.5 }}
                                  className="italic text-md text-red-500 mt-1"
                                >
                                  {formik.errors[`voyage.voyageurs[${index}].dateNaissance`]}
                                </motion.div>
                              )}
                            </div>

                            {/* Adresse */}
                            <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0'>
                              <label className="block text-gray-700 font-semibold mb-1 pl-2">Adresse</label>
                              <div className='relative'>
                                <MdPlace className='absolute left-3 top-3 text-gray-400'/>
                              </div>
                              <input 
                                  disabled={!isContratModifiable()}
                                  type='text'
                                  name={`voyage.voyageurs.${index}.adresse`}
                                  value={voyageur.adresse}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder='Adresse'
                                  className={` ${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    getFieldError('adresse', index) ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                                  }`}
                                />
                                {getFieldError('adresse', index) && (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    className="italic text-md text-red-500 mt-1"
                                  >
                                    {formik.errors[`voyage.voyageurs[${index}].adresse`]}
                                  </motion.div>
                                )}
                            </div>

                            
                            {/* email */}
                            <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0'>
                              <label className="block text-gray-700 font-semibold mb-1 pl-2">Email</label>
                              <div className='relative'>
                                <FaEnvelope className='absolute left-3 top-3 text-gray-400'/>
                              </div>
                              <input
                                disabled={!isContratModifiable()} 
                                type='email'
                                name={`voyage.voyageurs.${index}.email`}
                                value={voyageur.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder='Email'
                                className={` ${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                  getFieldError('email', index) ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                                }`}
                              />
                              {getFieldError('email', index) && (
                                <motion.div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  transition={{ duration: 0.5 }}
                                  className="italic text-md text-red-500 mt-1"
                                >
                                  {formik.errors[`voyage.voyageurs[${index}].email`]}
                                </motion.div>
                              )}
                            </div>

                            {/* Passeport */}
                            <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0'>
                              <label className="block text-gray-700 font-semibold mb-1 pl-2">Passeport</label>
                              <div className='relative'>
                                <BsPassportFill className='absolute left-3 top-3 text-gray-400'/>
                              </div>
                              <input 
                                disabled={!isContratModifiable()}
                                type='text'
                                name={`voyage.voyageurs.${index}.passport`}
                                value={voyageur.passport}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder='Numéro de passeport'
                                className={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                  getFieldError('passport', index) ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                                }`}
                              />
                              {getFieldError('passport', index) && (
                                <motion.div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  transition={{ duration: 0.5 }}
                                  className="italic text-md text-red-500 mt-1"
                                >
                                  {formik.errors[`voyage.voyageurs[${index}].passport`]}
                                </motion.div>
                              )}
                            </div>

                            {/* Date de délivrance */}
                            <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0'>
                              <label className="block text-gray-700 font-semibold mb-1 pl-2">Date de délivrance</label>
                              <Datepicker
                                disabled={!isContratModifiable()}
                                i18n={"fr"}
                                useRange={false}
                                asSingle={true}
                                readOnly={true}
                                inputClassName={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 cursor-pointer border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                  getFieldError('dateDelivrance', index) ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                                }`}
                                value={voyageur.dateDelivrance ? { 
                                  startDate: voyageur.dateDelivrance, 
                                  endDate: voyageur.dateDelivrance 
                                } : null}
                                onChange={(newValue) => {
                                  formik.setFieldValue(
                                    `voyage.voyageurs.${index}.dateDelivrance`, 
                                    newValue?.startDate ? moment(newValue.startDate).format('YYYY-MM-DD') : ''
                                  );
                                }}
                                maxDate={new Date(today)}
                                displayFormat={"DD/MM/YYYY"}
                                placeholder="Sélectionner une date"
                                popoverDirection='up'
                              />
                              {getFieldError('dateDelivrance', index) && (
                                <motion.div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  transition={{ duration: 0.5 }}
                                  className="italic text-md text-red-500 mt-1"
                                >
                                  {formik.errors[`voyage.voyageurs[${index}].dateDelivrance`]}
                                </motion.div>
                              )}
                            </div>

                            {/* Date d'expiration */}
                            <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-20'>
                              <label className="block text-gray-700 font-semibold mb-1 pl-2">Date d'expiration</label>
                               <Datepicker
                                  disabled={!isContratModifiable()}
                                  i18n={"fr"}
                                  useRange={false}
                                  asSingle={true}
                                  readOnly={true}
                                  inputClassName={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 cursor-pointer border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    getFieldError('dateExp', index) ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                                  }`}
                                  value={voyageur.dateExp ? { startDate: voyageur.dateExp, endDate: voyageur.dateExp } : null}
                                  onChange={(newValue) => {
                                    formik.setFieldValue(`voyage.voyageurs.${index}.dateExp`, newValue?.startDate ? moment(newValue.startDate).format('YYYY-MM-DD') : '');
                                  }}
                                  minDate={new Date(today)}
                                  displayFormat={"DD/MM/YYYY"}
                                  placeholder="Sélectionner une date"
                                  popoverDirection='up'
                                />
                                {getFieldError('dateExp', index) && (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    className="italic text-md text-red-500 mt-1"
                                  >
                                    {formik.errors[`voyage.voyageurs[${index}].dateExp`]}
                                  </motion.div>
                                )}
                            </div>
                          </motion.div>
                        )}
                        </AnimatePresence>
                        
                      </motion.div>
                    ))}
                  </AnimatePresence>
                  

                  <div className='col-span-12 sm:col-span-12 md:col-span-12 lg:col</div>-span-12'>
                    <h1 className='font-bold text-lg text-white p-2 bg-purple-600  '>Les Informations du Souscripteur</h1>
                  </div>
                  
                  

                  {/* Type de souscripteur */}
                  <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6'>
                    <label className="block text-gray-700 font-semibold mb-1 pl-2">
                      Type de souscripteur
                    </label>
                    <div className='relative'>
                      <TbSwitchHorizontal className="absolute left-3 top-3 text-gray-400" />
                    </div>
                    <select
                      disabled={!isContratModifiable()}
                      name="souscripteur.type"
                      value={formik.values.souscripteur.type}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                        formik.errors.souscripteur?.type && formik.touched.souscripteur?.type ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                      }`}
                    >
                      <option value="">Sélectionnez</option>
                      <option value="particulier">Particulier</option>
                      <option value="entreprise">Entreprise</option>
                    </select>
                    {formik.errors.souscripteur?.type && formik.touched.souscripteur?.type && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500 mt-1"
                      >
                        {formik.errors.souscripteur.type}
                      </motion.div>
                    )}
                  </div>


                  <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 '>
                    <div className='flex justify-center self-end'>
                      {selectedSubscriber !== null && (
                        <motion.button
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={handleUnselectSouscripteur}
                          className="flex items-center  space-x-1 bg-red-500 text-white p-2 rounded-md hover:bg-red-600 "
                        >
                          <MdDeleteForever size={18} />
                          <span className="text-md">Enlever le souscripteur actuel</span>
                        </motion.button>
                      )}
                    </div>
                  </div>

                    {formik.values.souscripteur.type === 'particulier' && (
                      <>
                        {/* Genre */}
                        <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-4'>
                          <label className="block text-gray-700 font-semibold mb-1 pl-2">
                            Genre
                          </label>
                          <div className='relative'>
                            <ImManWoman className="absolute left-3 top-3 text-gray-400" />
                          </div>
                          <select
                            disabled={!isContratModifiable()}
                            name="souscripteur.genre"
                            value={formik.values.souscripteur.genre}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                              formik.errors.souscripteur?.genre && formik.touched.souscripteur?.genre ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                            }`}
                          >
                            <option value="">Sélectionnez</option>
                            <option value="Monsieur">Monsieur</option>
                            <option value="Madame">Madame</option>
                          </select>
                          {formik.errors.souscripteur?.genre && formik.touched.souscripteur?.genre && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.5 }}
                              className="italic text-md text-red-500 mt-1"
                            >
                              {formik.errors.souscripteur.genre}
                            </motion.div>
                          )}
                        </div>

                        {/* Prénom */}
                        <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-4'>
                          <label className="block text-gray-700 font-semibold mb-1 pl-2">
                            Prénom
                          </label>
                          <div className='relative'>
                            <FaUserPen className="absolute left-3 top-3 text-gray-400" />
                          </div>
                          <input
                            disabled={!isContratModifiable()}
                            type="text"
                            name="souscripteur.prenom"
                            value={formik.values.souscripteur.prenom}  
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Prénom"
                            className={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                              formik.errors.souscripteur?.prenom && formik.touched.souscripteur?.prenom ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                            }`}
                          />
                          {formik.errors.souscripteur?.prenom && formik.touched.souscripteur?.prenom && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.5 }}
                              className="italic text-md text-red-500 mt-1"
                            >
                              {formik.errors.souscripteur.prenom}
                            </motion.div>
                          )}
                        </div>

                        {/* Nom */}
                        <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-4'>
                          <label className="block text-gray-700 font-semibold mb-1 pl-2">
                            Nom
                          </label>
                          <div className='relative'>
                            <FaUserPen className="absolute left-3 top-3 text-gray-400" />
                          </div>
                          <input
                            disabled={!isContratModifiable()}
                            type="text"
                            name="souscripteur.nom"
                            value={formik.values.souscripteur.nom}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Nom"
                            className={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                              formik.errors.souscripteur?.nom && formik.touched.souscripteur?.nom ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                            }`}
                          />
                          {formik.errors.souscripteur?.nom && formik.touched.souscripteur?.nom && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.5 }}
                              className="italic text-md text-red-500 mt-1"
                            >
                              {formik.errors.souscripteur.nom}
                            </motion.div>
                          )}
                        </div>
                      </>
                    )}

                    {formik.values.souscripteur.type === 'entreprise' && (
                      <div className='col-span-12'>
                        <label className="block text-gray-700 font-semibold mb-1 pl-2">
                          Raison sociale
                        </label>
                        <div className='relative'>
                          <BsBuildingsFill className="absolute left-3 top-3 text-gray-400" />
                        </div>
                        <input
                          disabled={!isContratModifiable()}
                          type="text"
                          name="souscripteur.raison"
                          value={formik.values.souscripteur.raison}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Raison sociale"
                          className={` ${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                            formik.errors.souscripteur?.raison && formik.touched.souscripteur?.raison ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                          }`}
                        />
                        {formik.errors.souscripteur?.raison && formik.touched.souscripteur?.raison && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="italic text-md text-red-500 mt-1"
                          >
                            {formik.errors.souscripteur.raison}
                          </motion.div>
                        )}
                      </div>
                    )}

                    {/* Téléphone */}
                    <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-10'>
                      <label className="block text-gray-700 font-semibold mb-1 pl-2">
                        Téléphone
                      </label>
                      <PhoneInput
                        country={'sn'}
                        onlyCountries={['sn', 'gn', 'ml', 'ci']}
                        masks={{ 
                          sn: '-..-...-..-..',
                          gn: '-..-...-...',
                          ml: '-..-...-...',
                          ci: '-..-..-..-..'
                        }}
                        disabled={!isContratModifiable()} // Ajout de disabled
                        value={formik.values.souscripteur.tel}
                        onChange={(value) => formik.setFieldValue('souscripteur.tel', value)}
                        containerClass="relative"
                        inputStyle={{
                          width: '100%',
                          height: '2.5rem',
                          paddingLeft: '3rem',
                          paddingRight: '1rem',
                          borderRadius: '0.5rem',
                          backgroundColor: !isContratModifiable() ? '#ecfccb' : 'white', // Ajout du background
                          border: formik.errors.souscripteur?.tel && formik.touched.souscripteur?.tel ? '1px solid #EF4444' : '1px solid #E5E7EB'
                        }}
                        inputProps={{
                          className: 'focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent z-10'
                        }}
                      />
                      {formik.errors.souscripteur?.tel && formik.touched.souscripteur?.tel && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className="italic text-md text-red-500 mt-1"
                        >
                          {formik.errors.souscripteur.tel}
                        </motion.div>
                      )}
                    </div>

                    {/* Email */}
                    <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0'>
                      <label className="block text-gray-700 font-semibold mb-1 pl-2">
                        Email
                      </label>
                      <div className='relative'>
                        <FaEnvelope className="absolute left-3 top-3 text-gray-400" />
                      </div>
                      <input
                        disabled={!isContratModifiable()}
                        type="email"
                        name="souscripteur.email"
                        value={formik.values.souscripteur.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Email"
                        className={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                          formik.errors.souscripteur?.email && formik.touched.souscripteur?.email ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                        }`}
                      />
                      {formik.errors.souscripteur?.email && formik.touched.souscripteur?.email && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className="italic text-md text-red-500 mt-1"
                        >
                          {formik.errors.souscripteur.email}
                        </motion.div>
                      )}
                    </div>

                    {/* Adresse */}
                    <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0'>
                      <label className="block text-gray-700 font-semibold mb-1 pl-2">
                        Adresse
                      </label>
                      <div className='relative'>
                        <MdPlace className="absolute left-3 top-3 text-gray-400" />
                      </div>
                      <input
                        disabled={!isContratModifiable()}
                        type="text"
                        name="souscripteur.adresse"
                        value={formik.values.souscripteur.adresse}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Adresse"
                        className={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                          formik.errors.souscripteur?.adresse && formik.touched.souscripteur?.adresse ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                        }`}
                      />
                      {formik.errors.souscripteur?.adresse && formik.touched.souscripteur?.adresse && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className="italic text-md text-red-500 mt-1"
                        >
                          {formik.errors.souscripteur.adresse}
                        </motion.div>
                      )}
                    </div>

                    {/* Profession/Domaine */}
                    <div className='col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6 z-0'>
                      <label className="block text-gray-700 font-semibold mb-1 pl-2">
                        {formik.values.souscripteur.type === 'particulier' ? 'Profession' : 'Domaine d\'activité'}  
                      </label>
                      <div className='relative'>
                        <MdWork className="absolute left-3 top-3 text-gray-400" />
                        <input
                          disabled={!isContratModifiable()}
                          type="text"
                          name="souscripteur.domaine"
                          value={formik.values.souscripteur.domaine}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`${!isContratModifiable() ? 'bg-lime-100': ''} pl-10 pr-4 py-2 border rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                            formik.errors.souscripteur?.domaine && formik.touched.souscripteur?.domaine ? 'border-red-500 focus:ring-red-500' : 'border-gray-300'
                          }`}
                        />
                        {formik.errors.souscripteur?.domaine && formik.touched.souscripteur?.domaine && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="italic text-md text-red-500 mt-1"
                          >
                            {formik.errors.souscripteur.domaine}
                          </motion.div>
                        )}
                      </div>
                    </div>

                </div>
              </div>
  
              <div className="mt-auto pt-4 self-end">
              <button 
                type="submit" 
                className={`flex space-x-3 items-center py-2 px-4 rounded transform transition duration-300 hover:scale-110 
                  ${isFormVOK() 
                    ? 'bg-blue-500 text-white cursor-pointer' 
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                disabled={!isFormVOK() || chargement }
              >
                <span>
                  <span className="flex items-center">
                        {
                            chargement ? (<Spinner color="info" size="md"  />) : (<FaCheckCircle size={18}  />)
                        }
                        <p className="pl-2">{
                            chargement ? "En Cours de Modification..." : 'Modifier'
                        }</p>
                    </span>
                </span>
              </button>
            </div>
            </form>
          </div>
  
          <div className='col-span-1 p-2 border-[0.5px] rounded-2xl shadow-md  bg-white'>
            <div className='container mx-auto p-2 md:p-4 lg:sticky lg:top-20 overflow-y-auto max-h-screen '>
                <div className="bg-gradient-to-br from-violet-50 via-purple-50 to-indigo-100 p-6 shadow-lg rounded-lg mb-6 border-[0.5px] border-teal-600">
                  <h3 className="flex justify-center items-center text-xl font-bold text-indigo-700 mb-4 underline underline-offset-4">
                    Détails du voyage
                  </h3>
                  <ul>
                    <li className="flex items-center mb-2"> 
                      <span className="text-purple-600 text-2xl mr-2">👥</span>
                      <span className="text-dark-600 font-DMSerifText">
                        Nombre de voyageur : <span className="font-semibold text-purple-700 ml-2">
                          {formik.values.voyage.voyageurs.length}
                        </span>
                      </span>
                    </li>
                    <li className="flex items-center mb-2">
                      <span className="text-purple-600 text-2xl mr-2">📍</span>
                      <span className="text-dark-600 font-DMSerifText">
                        Pays de résidence : <span className="font-semibold text-purple-700 ml-2">
                            {voyage?.depart?.nom}
                        </span>
                      </span>
                    </li>
                    <li className="flex items-center mb-2">
                      <span className="text-purple-600 text-2xl mr-2">🛫</span>
                      <span className="text-dark-600 font-DMSerifText">
                        Date de départ : <span className="font-semibold text-purple-700 ml-2">
                          {formik.values.voyage.dateDepart ? moment(formik.values.voyage.dateDepart).format('DD/MM/YYYY') : '-'}
                        </span>
                      </span>
                    </li>
                    <li className="flex items-center mb-2">
                      <span className="text-purple-600 text-2xl mr-2">🌍</span>
                      <span className="text-dark-600 font-DMSerifText">
                        Destination : <span className="font-semibold text-purple-700 ml-2">
                            {voyage?.destination?.nom}
                        </span>
                      </span>
                    </li>
                    <li className="flex items-center mb-2">
                      <span className="text-purple-600 text-2xl mr-2">🛬</span>
                      <span className="text-dark-600 font-DMSerifText">
                        Date de retour : <span className="font-semibold text-purple-700 ml-2">
                          {formik.values.voyage.dateRetour ? moment(formik.values.voyage.dateRetour).format('DD/MM/YYYY') : '-'}
                        </span>
                      </span>
                    </li>
                    <li className="flex items-center mb-2">
                      <span className="text-purple-600 text-2xl mr-2">📅</span>
                      <span className="text-dark-600 font-DMSerifText">
                        Durée : <span className="font-semibold text-purple-700 ml-2">
                          {formik.values.voyage.dateDepart && formik.values.voyage.dateRetour ? 
                            `${moment(formik.values.voyage.dateRetour).diff(moment(formik.values.voyage.dateDepart), 'days')} jours` : 
                            '-'
                          }
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="bg-gradient-to-br from-violet-50 via-purple-50 to-indigo-100 border-[0.5px] border-teal-600 p-6 shadow-lg rounded-lg">
                  <h3 className="flex justify-center items-center text-xl font-bold text-indigo-700 mb-4 underline underline-offset-4">Prime à payer</h3>
                  <ul>
                    <li className="flex justify-between">
                      <span className="text-gray-800 font-DMSerifText">Prime Nette: </span>
                      <span className="flex flex-row justify-center items-baseline font-semibold text-green-500">
                        {
                          loading ? <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-green-500"></div> : <FormatMontant montant={tarification?.formules?.[0]?.primeTTCTotal} />
                        }
                        <span className='text-xs ml-1'>{devis}</span>
                      </span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-800 font-DMSerifText">Coût de police: </span>
                      <span className="flex flex-row justify-center items-baseline font-semibold text-green-500">
                        {
                          loading ? <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-green-500"></div> : <FormatMontant montant={tarification?.formules?.[0]?.policeTotal} />
                        }
                        <span className='text-xs ml-1'>{devis}</span>
                      </span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-800 font-DMSerifText">Taxes: </span>
                      <span className="flex flex-row justify-center items-baseline font-semibold text-green-500">
                        {
                          loading ? <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-green-500"></div> : <FormatMontant montant={tarification?.formules?.[0]?.taxesTotal} />
                        }
                        <span className='text-xs ml-1'>{devis}</span>
                      </span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-800 font-DMSerifText">Frais de service: </span>
                      <span className="flex justify-items-center items-baseline font-semibold text-green-500">
                        {
                          loading ? <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-green-500"></div> : <FormatMontant montant={tarification?.formules?.[0]?.prixConseilleTotal} />
                        }
                        <span className='text-xs ml-1'>{devis}</span>
                      </span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-800 font-DMSerifText">Prime TTC: </span>
                      <span className="flex flex-row justify-center items-baseline font-semibold text-green-500">
                        {
                          loading ? <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-green-500"></div> : <FormatMontant montant={tarification?.formules?.[0]?.primeTTCTotal} />
                        }
                        <span className='text-xs ml-1'>{devis}</span>
                      </span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-800 font-DMSerifText">Commission: </span>
                      <span className="flex flex-row justify-center items-baseline font-semibold text-green-500">
                        {
                          loading ? <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-green-500"></div> : <FormatMontant montant={0} />
                        }
                        <span className='text-xs ml-1'>{devis}</span>
                      </span>
                    </li>
                    
                    <li className="flex justify-between font-bold text-red-600 text-lg mt-4 ">
                      <span>Total: </span>
                      <span className='flex flex-row justify-center items-baseline'>
                        {
                          loading ? <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-green-500"></div> : <FormatMontant montant={tarification?.formules?.[0]?.montantTotal} />
                        }
                        <span className='text-xs ml-1'>{devis}</span>
                      </span>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default EditContrat
