import React, { useEffect, useState } from 'react'
import Sidebar from '../../../template/sidebar/Sidebar'
import HomeNavbar from '../../../template/navbar/HomeNavbar';
import AssureurBanner from '../../../template/banner/AssureurBanner';
import { SiAdguard } from "react-icons/si";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { FcBullish , FcCurrencyExchange } from "react-icons/fc";
// import logo from '/images/logo.png';
import MyModal from '../../../components/dialog/MyModal';
import AddAssureur from '../../../components/forms/assureurs/AddAssureur';
import { GetAllAssureur, NewAssureur, NewAssureurGN } from '../../../api/ressources/CompagniesAssurances';
import { failNotification, succesNotification } from '../../../components/notifications/Notifications';
import AussureurSkeleton from '../../../components/loading/AussureurSkeleton';
import { motion } from 'framer-motion';
import OptionsAssureurs from '../../../components/ressources/OptionsAssureurs';
import FormatNumero from '../../../utils/FormatNumero';
import { useProfile } from '../../../UserDataStock/Profils/Profile';
import { FaGlobeAfrica } from 'react-icons/fa';


const AssureursList  = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chargement, setChargement] = useState(true);
  const [lesAssureurs, setLesAssureurs] = useState([]);
  const [refresh, setRefresh] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("SEN");
  const {position, token, userDetails } = useProfile();

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  const pays = [
    { id: 1, label: 'Senegal', flag: 'SEN' },
    { id: 2, label: 'Côte d\'Ivoire', flag: 'CIV' },
    { id: 3, label: 'Guinée Conakry', flag: 'GUI' },
    { id: 4, label: 'Mali', flag: 'MLI' },
  ]

  // enregistrement d'un assureur ///
  const formData = (data) =>{ 
    setIsLoading(true)
    var addInsurer = {
      code: data?.code,
      libelle: data?.libelle,
      description: data?.description,
      email: data?.email,
      logo: data?.logo,
      tel_fixe: data?.fixnumber,
      secu2: data?.secu2,
      secu4: data?.secu4,
      tel_mobile: data?.mobileNumber,
      code_courtier: data?.codeCourtier,
      addedBy: data?.addedBy,
      countryId: data?.country_id
    }

    const isGuinea = 
      position?.location?.country_code3 === "GIN" || 
      position?.location?.country_name === "Guinea" ||
      data?.country_id === "3";

    if(isGuinea){ 
      NewAssureurGN(addInsurer, token, position)
        .then(function(result) {
          console.log(result);
          if (result.status === 201) {
            succesNotification(result.data);
            setModalOpen(false); 
            Assureurs();
          } else {
            failNotification(result.data);
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(function() {
          setIsLoading(false);
        });
    }else{ 
      NewAssureur(addInsurer, token, position)
        .then(function(result) {
          if (result.status === 201) {
            succesNotification(result.data.message);
            setModalOpen(false); 
            Assureurs();
          } else {
            failNotification(result.data.message);
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(function() {
          setIsLoading(false);
        });
    }
}



  //// recuperation des assureurs ////
  async function Assureurs() {
    try {
      let response = await GetAllAssureur(token, selectedCountry);
      // Assurez-vous que response.data existe et est un tableau
      setLesAssureurs(Array.isArray(response) ? response : []);
      setChargement(true);
    } catch (err) {
      console.log(err);
      setLesAssureurs([]); // En cas d'erreur, définir un tableau vide
    } finally {
      setChargement(false);
    }
  }

  /// actualisation des donnes apres supression ////
  useEffect(() => { 
    if(refresh > 0){ 
      Assureurs();
    }
  }, [refresh])


  //// actualisation des donnes apres ajout ////
  useEffect(() => { 
    Assureurs();
  }, [selectedCountry]);


  return (
    <div className='flex h-screen'>
      <Sidebar isOpen={isOpen}  onToggle={handleOpenClose}/> 
      <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
      <HomeNavbar isOpen={isOpen} />
          <main className="p-6 mt-16">
            <AssureurBanner /> 
              
            {/* modal */}
           <MyModal 
           isOpen={modalOpen}
           closeModal={()=>setModalOpen(false)}
           content={<AddAssureur handleRegistration={formData}  isLoading={isLoading} /> }
           />

            {/* ajouter assureur  */}
            <div className='flex justify-end space-x-6 items-center '>

              <div className='relative'>
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <FaGlobeAfrica className="w-5 h-5 text-gray-400 " />
                </span>
                <select
                  id='country'
                  name='country'
                  onChange={(e) => {
                    setSelectedCountry(e.target.value);
                    Assureurs();
                  }}
                  value={selectedCountry}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg h-10 pl-10 pr-4 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-transparent"
                >
                  {pays.map((option) => (
                    <option key={option.id} value={option.flag}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className='flex space-x-2 py-2 px-4 mr-5 bg-purple-300 text-white items-center rounded-lg cursor-pointer transform transition duration-300 hover:scale-110 hover:bg-purple-700 hover:text-white'
                onClick={()=>setModalOpen(true)}>
                  <BsFillPlusCircleFill className='w-8 h-7' />
                  <h1 className='text-lg font-semibold'>Ajouter</h1>
              </div>
            </div>

            {/* le nombre d'assureur */}
            <div className='flex justify-start items-center space-x-3'>
              <h1 className='text-2xl p-2 underline font-semibold'>Assureurs</h1>
              <div className='bg-purple-600 px-2 rounded-md text-white '>
                {lesAssureurs.length}
              </div>
            </div>
            
            {/* l'assureur */}
              <div className="grid grid-cols-12 gap-5">
                
                {
                  chargement ? (
                    <>
                      <AussureurSkeleton /> 
                    </>
                  ) : (
                    <>
                      {
                        lesAssureurs.map((as, index)=>(
                          <motion.div
                            key={as.id}
                            className="col-span-6 lg:col-span-3 py-4 border rounded-2xl shadow-md px-4 bg-white"
                            initial={{ opacity: 0, x: '-100%', scale: 0.5 }}
                            animate={{ opacity: 1, x: 0, scale: 1 }}
                            exit={{ opacity: 0, x: '-100%', scale: 0.5 }}
                            transition={{ duration: 0.5, ease: 'easeIn', delay: index * 0.1 }}
                          >
                            {/* le bouton menu */}
                              
                             <OptionsAssureurs  active={as} confirmation={setRefresh}/>
                              {/* logo & name assureur */}
                              <div className='flex justify-between'>
                                <div className='flex'>
                                  <div className='w-24 h-24 rounded-md border shadow-md bg-white'>
                                    <img src={as.logo} className='p-2 object-cover' alt="image" />
                                  </div>
                                  <div className='p-4'>
                                    <div className='flex justify-start items-baseline space-x-2'>
                                      <h1 className='text-xl font-semibold'>{as.libelle}</h1>
                                      <SiAdguard className='text-teal-700' /> 
                                    </div>
                                    <div className='flex space-x-2'>
                                      <h1 className='font-semibold text-teal-600'>Code Courtier: </h1>
                                      <h1 className='font-semibold text-teal-600'>{as.code_courtier}</h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* les details assureurs */}
                              <div className='py-6'>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Email: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'>{as.email}</h1>
                                </div>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Telephone Fixe: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'><FormatNumero numero={as.tel_fixe}/></h1>
                                </div>
                                <div className='flex justify-between'>
                                  <h1 className='text-lg font-bold underline'>Telephone Mobile: </h1>
                                  <h1 className='font-bold mr-4 text-teal-700'><FormatNumero numero={as.tel_mobile} /></h1>
                                </div>
                              </div>
                              <hr />
                              {/* ventes infos assureurs */}
                              <div className='flex justify-between pt-4'>
                                <div className='flex justify-between items-end space-x-2'>
                                  <div className='w-14 h-14'>
                                    <FcBullish className='w-full h-full p-1'/> 
                                  </div>
                                  <div className=''>
                                    <h1 className='text-sm font-semibold underline'>Volume Ventes</h1>
                                    <h1 className='font-semibold text-center'>1 000</h1>
                                  </div>
                                </div>

                                <div className='flex justify-between items-end space-x-2'>
                                  <div className='w-14 h-14'>
                                    <FcCurrencyExchange className='w-full h-full p-1'/> 
                                  </div>
                                  <div className=''>
                                    <h1 className='text-sm font-semibold underline'>Montant Ventes</h1>
                                    <h1 className='font-semibold text-center'>205 145 458 fcfa</h1>
                                  </div>
                                </div>
                              </div>
                            </motion.div>
                        ))
                      }
                    </>
                  )
                }
              </div>
           </main>
      </div>
    </div>
  )
}

export default AssureursList
