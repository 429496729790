import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import PaysAriv from '../../inputs/PaysAriv'
import { BsPatchCheckFill } from 'react-icons/bs'
import Lottie from 'lottie-react'
import FormatMontant from '../../../utils/FormatMontant';
import charge from "/src/images/loader.json";
import { motion } from 'framer-motion';
import Datepicker from "react-tailwindcss-datepicker";
import { GetAllAssureur, GetAssurforSubscribe } from '../../../api/ressources/CompagniesAssurances'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
import { FaCalendarDay, FaPerson } from 'react-icons/fa6'
import moment from 'moment'
import { GetPaysDest } from '../../../api/ressources/Autres'
import { getTarifVoyage, getTarifVoyageGn } from '../../../api/voyages/TarifAndFormulesVoy'
import { AssureVoyageValidation } from '../../../utils/FormValidation'
import { useProfile } from '../../../UserDataStock/Profils/Profile'



const Step1 = ({onNext}) => {

  const savedData = JSON.parse(sessionStorage.getItem('formVoyage'))?.step1Data || {};
  
  // États
  const [waiting, setWaiting] = useState(true);
  const [insurers, setInsurers] = useState([]);
  const [pays, setPays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedInsurer, setSelectedInsurer] = useState(null);
  const [tarification, setTarification] = useState([]);
  const [selectedTarification, setSelectedTarification] = useState(null);
  const [today, setToday] = useState(moment().format('YYYY-MM-DD'));
  const [devis, setDevis] = useState("");
  const { position, token, userDetails } = useProfile();
  const [departId, setDepartId] = useState(193);

  

  const paysDepart = [
    { id: 193, name: "Sénégal" },
    { id: 134, name: "Mali" },
    { id: 324, name: "Guinée" },
    { id: 110, name: "Côte d'Ivoire" },
  ];

  const verifierPaysDepart = (position, paysDepart) => {
    if (!position?.location?.country_name) return null;
    
    const paysActuel = position.location.country_name;
    const paysCorrespondant = paysDepart.find(pays => 
      pays.name.toLowerCase() === paysActuel.toLowerCase()
    );
  
    return paysCorrespondant?.id || 193;
  };

  useEffect(() => {
    const paysId = verifierPaysDepart(position, paysDepart);
    if (paysId) {
      setDepartId(paysId);
      formik.setFieldValue('departId', paysId);
    }
  }, [position]);

  // Configuration Formik
  const formik = useFormik({
    initialValues: {
      departId: savedData.departId || departId,
      destination: savedData.destination || '',
      voyagers: savedData.voyagers || [],
      duree: savedData.duree || '',
      dateDepart: savedData.dateDepart || '',
      dateRetour: savedData.dateRetour || '',
      verif: savedData.verif || '1',
      nbVoyager: savedData.nbVoyager || '',
      selectedTarification: savedData.selectedTarification || null,
    },
    validate: AssureVoyageValidation,
    onSubmit: (values) => {
      const existingData = JSON.parse(sessionStorage.getItem('formVoyage')) || {};
      const newData = { ...existingData, step1Data: values };
      sessionStorage.setItem('formVoyage', JSON.stringify(newData));
      onNext(values);
    }
  });

  const handleDateDepartChange = (newValue) => {
    formik.setFieldValue('dateDepart', newValue?.startDate ? moment(newValue.startDate).format('YYYY-MM-DD') : '');
  };
  
  const handleDateRetourChange = (newValue) => {
    formik.setFieldValue('dateRetour', newValue?.startDate ? moment(newValue.startDate).format('YYYY-MM-DD') : '');
  };

  const responseOptions = [
    { id: '1', label: 'Oui' },
    { id: '2', label: 'Non' },
  ];

  const handleNbVoyagerChange = (e) => {
    const newNbVoyager = e.target.value === '' ? '' : parseInt(e.target.value) || 0;
    const newVoyagers = newNbVoyager > 0 ? Array.from({ length: newNbVoyager }, (_, index) => ({
      assure: `Assuré ${index + 1}`,
      dateNaissance: '',
    })) : [];
    
    formik.setFieldValue('nbVoyager', newNbVoyager);
    formik.setFieldValue('voyagers', newVoyagers);
  };

  // const calculateDuration = (dateDepart, dateRetour) => {
  //   return moment(dateRetour).diff(moment(dateDepart), 'days');
  // };

  // const calculateReturnDate = (dateDepart, duree) => {
  //   return moment(dateDepart).add(duree, 'days').format('YYYY-MM-DD');
  // };

  const calculateDuration = (dateDepart, dateRetour) => {
    // Ajouter +1 pour inclure la date de départ
    return moment(dateRetour).diff(moment(dateDepart), 'days') + 1;
  };

  const calculateReturnDate = (dateDepart, duree) => {
    // Soustraire 1 car la durée inclut déjà le jour de départ
    return moment(dateDepart).add(duree - 1, 'days').format('YYYY-MM-DD');
  };

  
  async function getTarif(data) {
      if (!data || !token || !position) {
        console.error('Données manquantes pour la récupération des tarifs');
        return;
      }
    
      setLoading(true);
      setTarification([]);
      setSelectedInsurer(null);
      setSelectedTarification(null);
      formik.setFieldValue('selectedTarification', null);
    
      const donnes = {
        destination: data?.destinationId,
        duree: data?.duree,
        agesArray: data?.ages,
      };
    
      try {
        let tarifFunction;
        if (position?.location?.country_code3 === "GIN") {
          tarifFunction = getTarifVoyageGn;
          setDevis("GNF");
        } else {
          tarifFunction = getTarifVoyage;
          setDevis("XOF");
        }

        const result = await tarifFunction(donnes, token, position);
        console.log(result);
        
        if (result?.status === 200) {
          if (result.data?.length > 0) {
            setTarification(result.data);
          } else {
            console.warn('Aucun tarif disponible pour ces critères');
          }
        } else {
          throw new Error('Réponse serveur invalide');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des tarifs:', error.message);
        setTarification([]);
      } finally {
        setLoading(false);
      }
  }

  const resetTarification = () => {
    setTarification([]);
    setSelectedInsurer(null);
    setSelectedTarification(null);
    formik.setFieldValue('selectedTarification', null);
  };

  useEffect(() => {
    const { destinationId, duree, voyagers } = formik.values;
  
    const calculateAge = (dateString) => moment().diff(moment(dateString, 'YYYY-MM-DD'), 'years');
  
    if (!destinationId || !duree || !voyagers.length) {
      resetTarification();
      return;
    }
  
    const hasOverAgeVoyager = voyagers.some(v => {
      if (!v.dateNaissance) return false;
      const age = calculateAge(v.dateNaissance);
      return age > 75;
    });
  
    if (hasOverAgeVoyager) {
      formik.setFieldError('voyagers', "L'âge maximum autorisé est de 65 ans");
      resetTarification();
      return;
    }
  
    const isValidDateFormat = voyagers.every(v => 
      !v.dateNaissance || moment(v.dateNaissance, 'YYYY-MM-DD', true).isValid()
    );
  
    if (voyagers.every(v => v.dateNaissance) && isValidDateFormat) {
      const ages = voyagers.map(v => calculateAge(v.dateNaissance));
      getTarif({ destinationId, duree, ages });
    } else {
      resetTarification();
    }
  }, [formik.values.destinationId, formik.values.duree, formik.values.voyagers]);

  useEffect(() => {
    const { dateDepart, dateRetour, verif, duree } = formik.values;

    if (verif === '1' && dateDepart && dateRetour) {
      formik.setFieldValue('duree', calculateDuration(dateDepart, dateRetour));
    } else if (verif === '2' && dateDepart && duree) {
      formik.setFieldValue('dateRetour', calculateReturnDate(dateDepart, duree));
    }

    if (dateDepart && dateRetour && moment(dateDepart).isAfter(dateRetour)) {
      formik.setFieldValue('dateDepart', moment(dateRetour).subtract(2, 'days').format('YYYY-MM-DD'));
    }
  }, [formik.values.verif, formik.values.dateDepart, formik.values.duree, formik.values.dateRetour]);

  // async function fetchAssureurs() {
  //   try {
  //     const response = await GetAssurforSubscribe(token, position);
  //     setInsurers(response);
  //     setWaiting(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  async function fetchAssureurs() {
    try {
      let response;
      if (position?.location?.country_code3 === "GIN"){
        response = await GetAssurforSubscribe(token, position);
        setInsurers(response);
        setWaiting(false);
      } else{
        response = await GetAllAssureur(token, position);
        setInsurers(response);
        setWaiting(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchPaysDestination() { 
    try { 
      const response = await GetPaysDest(token, position);
      setPays(response);
      setWaiting(false);
    } catch (err) { 
      console.log(err);
    }
  }

  // useEffect(() => {
  //   // Utilise directement les données du fichier JSON
  //   setPays(PaysDestinations.pays);
  //   setWaiting(false);
  // }, []);

  useEffect(() => { fetchPaysDestination(); }, []);
  useEffect(() => { fetchAssureurs(); }, []);

  const toggleSelection = (insurer) => {
    const tarificationAssociee = tarification.find(t => t.assurance.id === insurer.id);
    
    if (tarificationAssociee) {
      setSelectedInsurer({ 
        ...insurer, 
        formules: tarificationAssociee?.formules || [] 
      });
      setSelectedTarification(tarificationAssociee);
      formik.setFieldValue('selectedTarification', tarificationAssociee);
    } else {
      // Réinitialiser si aucune tarification n'est trouvée
      setSelectedInsurer(null);
      setSelectedTarification(null);
      formik.setFieldValue('selectedTarification', null);
    }
  };


    
  return (
    <div className='grid grid-cols-1  lg:grid-cols-3 gap-2 md:gap-4 container mx-auto py-4 md:py-8'>
     
      {/* le formulaire */}
      <div className='col-span-1 lg:col-span-2  bg-white p-4 md:p-6 lg:p-8 rounded-xl border shadow-md h-full'>
        <form onSubmit={formik.handleSubmit} className='h-full flex flex-col'>
          <div className="flex-grow relative">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-12 gap-4 md:gap-6 lg:gap-8 py-4">
              {/* Pays selection - Full width */}
              <div className="col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-12">
                <PaysAriv formik={formik} paysOptions={pays}/> 
              </div>
              <div className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-4">
                <label htmlFor="nbVoyager" className="block text-gray-700 font-semibold mb-1 pl-2">Voyageur(s)</label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FaPerson className="w-5 h-5 text-gray-400" />
                  </span>
                  <input
                    type='number'
                    id='nbVoyager'
                    name='nbVoyager'
                    onChange={handleNbVoyagerChange}
                    value={formik.values.nbVoyager}
                    onBlur={formik.handleBlur}
                    placeholder='Nombre de voyageurs'
                    className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.nbVoyager && formik.touched.nbVoyager ? 'border-red-500' : ''}`}
                  />
                </div>
                <div className="h-6">
                  {formik.touched.nbVoyager && formik.errors.nbVoyager && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="italic text-md text-red-500"
                    >
                      {formik.errors.nbVoyager}
                    </motion.div>
                  )}
                </div>
              </div>

              <div className="col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-4">
                <label htmlFor="verif" className="block text-gray-700 font-semibold mb-1 pl-2">
                  Date de retour connue ?
                </label>
                <div className="relative">
                  <div className="flex space-x-6 ml-5">
                    {responseOptions.map(option => (
                      <motion.div
                        key={option.id}
                        className="flex items-center"
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <input
                          id={`verif-${option.id}`}
                          name="verif"
                          type="radio"
                          value={option.id}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.verif === option.id}
                          className={`mr-2 w-6 h-6 border cursor-pointer border-gray-300 rounded-full text-blue-600 bg-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-transparent ${formik.errors.verif && formik.touched.verif ? 'border-red-500' : ''}`}
                        />
                        <label htmlFor={`verif-${option.id}`} className="text-gray-700">{option.label}</label>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-4">
                <label htmlFor="duree" className="block text-gray-700 font-semibold mb-1 pl-2">Durée du voyage</label>
                <div className="relative">
                  <input
                    autoComplete="off"
                    id="duree"
                    name="duree"
                    onChange={formik.handleChange}
                    value={formik.values.duree}
                    onBlur={formik.handleBlur}
                    type="number"
                    placeholder='La Durée en jours'
                    disabled={formik.values.verif === '1'}
                    className={`pl-5 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent 
                      ${formik.errors.duree && formik.touched.duree ? 'border-red-500' : ''} 
                      ${formik.values.verif === '1' ? 'bg-gray-200 cursor-not-allowed' : ''}`}
                  />
                </div>
                <div className="h-6">
                  {formik.touched.duree && formik.errors.duree && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="italic text-md text-red-500"
                    >
                      {formik.errors.duree}
                    </motion.div>
                  )}
                </div>
              </div>

              <div className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6">
                <label htmlFor="dateDepart" className="block text-gray-700 font-semibold mb-1 pl-2">
                  Date de départ
                </label>
                <div className="relative">
                  <Datepicker
                    i18n={"fr"}
                    useRange={false}
                    asSingle={true}
                    readOnly={true}
                    inputClassName={`pl-5 py-2 cursor-pointer border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                      formik.errors.dateDepart && formik.touched.dateDepart ? 'border-red-500' : ''
                    }`}
                    value={formik.values.dateDepart ? { 
                      startDate: formik.values.dateDepart, 
                      endDate: formik.values.dateDepart 
                    } : null}
                    onChange={handleDateDepartChange}
                    onBlur={() => formik.setFieldTouched('dateDepart', true)}
                    minDate={new Date(today)}
                    displayFormat={"DD/MM/YYYY"}
                    placeholder="Sélectionner une date"
                  />
                </div>
                <div className="h-6">
                  {formik.touched.dateDepart && formik.errors.dateDepart && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="italic text-md text-red-500"
                    >
                      {formik.errors.dateDepart}
                    </motion.div>
                  )}
                </div>
              </div>

              <div className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6">
                <label htmlFor="dateRetour" className="block text-gray-700 font-semibold mb-1 pl-2">
                  Date de retour
                </label>
                <div className="relative">
                  <Datepicker
                    i18n={"fr"}
                    useRange={false}
                    asSingle={true}
                    readOnly={true}
                    disabled={formik.values.verif === '2'}
                    inputClassName={`pl-5 py-2 cursor-pointer border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                      formik.errors.dateRetour && formik.touched.dateRetour ? 'border-red-500' : ''
                    } ${formik.values.verif === '2' ? 'bg-gray-200 cursor-not-allowed' : ''}`}
                    value={formik.values.dateRetour ? { 
                      startDate: formik.values.dateRetour, 
                      endDate: formik.values.dateRetour 
                    } : null}
                    onChange={handleDateRetourChange}
                    onBlur={() => formik.setFieldTouched('dateRetour', true)}
                    minDate={formik.values.dateDepart ? moment(formik.values.dateDepart).add(2, 'days').toDate() : new Date(today)}
                    displayFormat={"DD/MM/YYYY"}
                    placeholder="Sélectionner une date"
                  />
                </div>
                <div className="h-6">
                  {formik.touched.dateRetour && formik.errors.dateRetour && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="italic text-md text-red-500"
                    >
                      {formik.errors.dateRetour}
                    </motion.div>
                  )}
                </div>
              </div>

              {formik.values.voyagers.map((voyager, index) => (
                <motion.div
                  key={index}
                  className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <label htmlFor={`voyagers.${index}.dateNaissance`} className="block text-gray-700 font-semibold mb-1 pl-2">
                    Date de naissance de {voyager.assure}
                  </label>
                  <div>
                    <input
                      type="date"
                      id={`voyagers.${index}.dateNaissance`}
                      name={`voyagers.${index}.dateNaissance`}
                      value={voyager.dateNaissance}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => {
                        const dateValue = e.target.value;
                        const isValidDate = moment(dateValue, 'YYYY-MM-DD', true).isValid();
                        
                        formik.setFieldValue(`voyagers.${index}.dateNaissance`, dateValue);
                        
                        if (!isValidDate) {
                          formik.setFieldError(`voyagers.${index}.dateNaissance`, 'Date invalide');
                        }
                      }}
                      onBlur={() => formik.setFieldTouched(`voyagers.${index}.dateNaissance`, true)}
                      className={`pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                        formik.errors.voyagers?.[index]?.dateNaissance && 
                        formik.touched.voyagers?.[index]?.dateNaissance ? 'border-red-500' : ''
                      }`}
                    />
                  </div>
                  <div className="h-6">
                    {formik.touched.voyagers?.[index]?.dateNaissance && 
                    formik.errors.voyagers?.[index]?.dateNaissance && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="italic text-md text-red-500"
                      >
                        {formik.errors.voyagers[index].dateNaissance}
                      </motion.div>
                    )}
                  </div>
                </motion.div>
              ))}

            </div>
          </div>

          <div className="mt-auto pt-4">
            <button 
              type="submit" 
              className={`flex space-x-3 items-center bg-blue-500 text-white py-2 px-4 rounded transform transition duration-300 hover:scale-110 
                ${(!formik.isValid || !selectedInsurer) ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={!formik.isValid || !selectedInsurer}
            >
              <span>Suivant</span>
              <IoIosArrowDroprightCircle size={20} />
            </button>
          </div>
        </form>
      </div>

      {/* les assureurs */}

      <div className='col-span-1 p-2 border-[0.5px] rounded-2xl shadow-md lg:sticky lg:top-0 overflow-y-auto max-h-screen'>
        <div className='container mx-auto p-2 md:p-4'>
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-2">
              {waiting ? (
                <div className="flex justify-center items-center">
                  <Lottie
                    animationData={charge}
                    style={{ width: 100, height: 100 }}
                    speed={1.5}
                  />
                </div>
              ) : (
                <>
                  {Array.isArray(insurers) &&
                    insurers.map((insurer) => (
                      <div
                        key={insurer.id}
                        className={`relative border rounded-lg p-4 shadow-lg cursor-pointer ${
                          selectedInsurer?.id === insurer.id ? "border-blue-500" : ""
                        }`}
                        onClick={() => toggleSelection(insurer)}
                      >
                        {selectedInsurer?.id === insurer.id && (
                          <BsPatchCheckFill className="absolute top-2 right-2 text-blue-500" />
                        )}
                        <div className="flex justify-center items-center mb-2">
                          <img
                            src={insurer.logo}
                            alt="Logo de l'assureur"
                            className="w-28 h-28 rounded border-[1.5px] p-1 shadow-md"
                          />
                        </div>
                        <div className="text-center mt-1 font-semibold text-teal-600">
                          Montant:
                        </div>
                        <div className="text-center font-bold text-lg text-teal-600">
                          {loading ? (
                            <div className="flex justify-center items-center">
                              <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-teal-600"></div>
                            </div>
                          ) : (
                            <div className='font-bold text-lg'>
                            <FormatMontant montant={tarification.find((t) => t.assurance.id === insurer.id)?.formules?.[0]?.montantTotal || 0}/>
                          </div>
                          )}
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>

          {/* Détails de l'assureur sélectionné */}
            <div className='mt-4'>
              <h2 className='text-lg text-center mb-4 font-semibold text-teal-600'>
                Décomposition de la prime de:
              </h2>
              <div className='text-center text-xl text-teal-600 font-bold mb-4'>
                {selectedInsurer?.libelle || 'Sélectionner un Assureur'}
              </div>
              <hr className='w-full h-2' />
              <div className='space-y-3 mt-4'>
                <div className='flex justify-between items-center'>
                  <div className='font-semibold text-teal-600'>Prime nette :</div>
                  <div className='font-bold text-green-500 '>
                    <FormatMontant montant={selectedInsurer?.formules?.[0]?.primeNetteTotal|| 0} />
                    <span className='ml-1'>{devis}</span>
                  </div>
                </div>
                <div className='flex justify-between items-center'>
                  <div className='font-semibold text-teal-600'>Coût de police :</div>
                  <div className='font-bold text-green-500 '>
                    <FormatMontant montant={selectedInsurer?.formules?.[0]?.policeTotal || 0} />
                    <span className='ml-1'>{devis}</span>
                  </div>
                </div>
                <div className='flex justify-between items-center'>
                  <div className='font-semibold text-teal-600'>Taxes :</div>
                  <div className='font-bold text-green-500'>
                    <FormatMontant montant={selectedInsurer?.formules?.[0]?.taxesTotal || 0} />
                    <span className='ml-1'>{devis}</span>
                  </div>
                </div>
                <div className='flex justify-between items-center'>
                  <div className='font-semibold text-teal-600'>Frais de service :</div>
                  <div className='font-bold text-green-500 '>
                    <FormatMontant montant={selectedInsurer?.formules?.[0]?.prixConseilleTotal || 0} />
                    <span className='ml-1'>{devis}</span>
                  </div>
                </div>
                <div className='flex justify-between items-center'>
                  <div className='font-semibold text-teal-600'>Prime TTC :</div>
                  <div className='font-bold text-green-500 '>
                    <FormatMontant montant={selectedInsurer?.formules?.[0]?.primeTTCTotal || 0} />
                    <span className='ml-1'>{devis}</span>
                  </div>
                </div>
                
              </div>
              <hr className='w-full h-2' />
              <div className='space-y-3 mt-4'>
                <div className='flex justify-between items-center'>
                  <div className='font-semibold text-teal-600'>TOTAL :</div>
                  <div className=' font-bold text-red-600 '>
                    <FormatMontant montant={selectedInsurer?.formules?.[0]?.montantTotal || 0} />
                    <span className='ml-1'>{devis}</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

    </div>
  )
}

export default Step1
