

import { useEffect, useState } from 'react';
import axios from 'axios';

export const useLocalisation = () => {
    
    const [location, setLocation] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        const url = `${import.meta.env.VITE_POSITION_URL}${import.meta.env.VITE_POSITION_KEY}`;
        // const url = `${import.meta.env.VITE_POSITION_URL}${import.meta.env.VITE_POSITION_KEY}&ip=102.176.175.255`;
        axios.get(url)
            .then(response => {
                const data = response.data;
                // console.log(data)
                if (data && data.ip) {
                    setLocation(data);
                } else {
                    setError('Impossible de récupérer la localisation.');
                }
            })
            .catch(() => {
                setError('Erreur lors de la récupération de la localisation.');
            });
    }, []);

    return { location, error };
};

