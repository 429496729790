import React, { useEffect, useState } from 'react';
import Sidebar from '../../template/sidebar/Sidebar';
import HomeNavbar from '../../template/navbar/HomeNavbar';
import AdminUserBanner from '../../template/banner/AdminUserBanner';
import { motion } from "framer-motion";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Select from 'react-select';
import VendeursContratDatatable from '../../components/datatables/voyagesDatatable/VendeursContratsDatatable';
import { useProfile } from '../../UserDataStock/Profils/Profile';
import { getAllDistributeurs } from '../../api/distributeurs/Distributeurs';
import SelectDistributeurs from '../../components/inputs/SelectDIstributeurs';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { GetAllContratsVoyageByDB, GetAllContratsVoyageByPV } from '../../api/voyages/DevisEtContratsVoyage';
import ExcelJS from 'exceljs';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment';
import paysData from '../../utils/pays.json';

const ContratsVoyageList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const { position, token, userDetails } = useProfile();
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [lesDistributeurs, setLesDistributeurs] = useState([]);
    const [isSelectLoading, setIsSelectLoading] = useState(false);
    const [selectedDistributeur, setSelectedDistributeur] = useState(null);
    const [numData, setNumData] = useState(0);
    const idpvInitial = userDetails?.pointDeVenteId;
    const [isLoadingDistributeurs, setIsLoadingDistributeurs] = useState(false);
    const [formattedData, setFormattedData] = useState([]);
    const [showExportModal, setShowExportModal] = useState(false);
    const [exportFilters, setExportFilters] = useState({
        assureur: '',
        pointDeVente: [], // Initialiser comme un tableau vide
        dateRange: {
            startDate: null,
            endDate: null
        }
    });

    const resetFilters = () => {
        setExportFilters({
            assureur: '',
            pointDeVente: [], // Réinitialiser comme un tableau vide
            dateRange: {
                startDate: null,
                endDate: null
            }
        });
    };
    

    // Fonction pour charger les contrats
    const loadContratsVoyage = async () => {
        if (!token || !position) {
            console.error('Token ou position manquant');
            return;
        }
    
        // Nettoyage des données avant chargement
        setRowData([]);
        setNumData(0);
        setIsLoading(true);
    
        try {
            let donnes;
            if (selectedDistributeur) {
                donnes = await GetAllContratsVoyageByDB(token, position, selectedDistributeur);
            } else {
                donnes = await GetAllContratsVoyageByPV(token, position, idpvInitial);
            }
    
            if (!donnes) return;
            // console.log(donnes)
    
            const formattedData = donnes.map(data => ({

                id: data.contrat.ref,
                modifc: data.contrat.edited,
                date: data.contrat.dateContrat || data.contrat.dateDevis, 
                policeNo: data.contrat.ref,
                souscripteur: {
                    nom: `${data.subscriber.prenom} ${data.subscriber.nom}`,
                    telephone: data.subscriber.telephone,
                    email: data.subscriber.email,
                    social: data.subscriber.raisonSociale
                },
                createdByUser: {
                    vendeur: `${data.createdByUser.prenom} ${data.createdByUser.nom}`
                },
                voyage: {
                    depart: data.voyage.dateDepart,
                    retour: data.voyage.dateRetour,
                    duree: data.contrat.duree,
                    destination: data.voyage.destinationId
                },
                formule:{
                    libelle: data.formule.name
                },
                decomposition: {
                    primeNette: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.primeNette, 0),
                    accessoires: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.accessoire, 0),
                    taxes: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.taxes, 0),
                    frais: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.fraisDeServices, 0),
                    primeTTC: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.primeTTC, 0),
                    montantTotal: data.contrat.DetailsContrats.reduce((acc, detail) => acc + detail.montantTotal, 0),
                },
                assureur:{
                    nom: data.assureur.libelle,
                },
                etat: data.contrat.isPaid ? 'recouvré' : 'non recouvré',
                pdv: data.pointDeVente.libelle,
                voyageur: {
                    liste: data.voyage.Voyageurs.map(voyageur => ({
                        id: voyageur.id,
                        genre: voyageur.genre,
                        nom: voyageur.nom,
                        prenom: voyageur.prenom,
                        dateNaissance: voyageur.dateNaissance,
                        telephone: voyageur.telephone,
                        email: voyageur.email,
                        adresse: voyageur.adresse,
                        numPassport: voyageur.numPassport,
                        dateExpiration: voyageur.dateExpiration,
                        dateDelivrance: voyageur.dateDelivrance,
                        primeTTC: voyageur.primeTTC
                    })),
                    nombre: data.voyage.Voyageurs.length
                },            
            }));
            setFormattedData(formattedData)
            setRowData(formattedData);
            setNumData(donnes.length);
        } catch (error) {
            console.error('Erreur lors du chargement des contrats:', error);
        } finally {
            setIsLoading(false);
        }
    };

   // Premier montage et changement de distributeur
    useEffect(() => {
        if (token && position) {
            loadContratsVoyage();
        }
    }, [token, position, selectedDistributeur]);

    
    // Chargement des distributeurs
    async function loadDistributeurs() {
        if (!token || !position) return;
    
        try {
            setIsLoadingDistributeurs(true);
            const response = await getAllDistributeurs(token, position);
            setLesDistributeurs(response || []);
        } catch (err) {
            console.error('Erreur chargement distributeurs:', err);
            setLesDistributeurs([]);
        } finally {
            setIsLoadingDistributeurs(false);
        }
    }

    useEffect(() => {
        loadDistributeurs();
    }, [token, position]);

    const filteredData = rowData.filter((row) => {
        const lowerSearchText = searchText.toLowerCase();
        return (
            row.id?.toLowerCase().includes(lowerSearchText) ||
            row.date?.toLowerCase().includes(lowerSearchText) ||
            row.policeNo?.toLowerCase().includes(lowerSearchText) ||
            row.souscripteur?.nom?.toLowerCase().includes(lowerSearchText) ||
            row.souscripteur?.telephone?.toLowerCase().includes(lowerSearchText) ||
            row.souscripteur?.email?.toLowerCase().includes(lowerSearchText) ||
            row.souscripteur?.social?.toLowerCase().includes(lowerSearchText) ||
            row.createdByUser?.vendeur?.toLowerCase().includes(lowerSearchText) ||
            row.voyage?.depart?.toLowerCase().includes(lowerSearchText) ||
            row.voyage?.retour?.toLowerCase().includes(lowerSearchText) ||
            row.formule?.libelle?.toLowerCase().includes(lowerSearchText) ||
            row.assureur?.nom?.toLowerCase().includes(lowerSearchText) ||
            row.etat?.toLowerCase().includes(lowerSearchText) ||
            row.pdv?.toLowerCase().includes(lowerSearchText)
        );
    });


    const ExportModal = ({ show, onClose, onExport, filters, setFilters, formattedData}) => {
        const uniqueAssureurs = [...new Set(formattedData.map(row => row.assureur?.nom))].filter(Boolean);
        const uniquePDV = [...new Set(formattedData.map(row => row.pdv))].filter(Boolean);

        // Formater les options pour react-select
        const pdvOptions = [
            { value: "tous", label: "Tous les points de vente" },
            ...uniquePDV.map(pdv => ({ value: pdv, label: pdv }))
        ]; 

                const handlePdvChange = (selectedOptions) => {
            // Si "Tous" est sélectionné
            if (selectedOptions?.find(option => option.value === "tous")) {
                // Sélectionner uniquement l'option "Tous"
                setFilters({...filters, pointDeVente: [pdvOptions[0]]});
            } else {
                setFilters({...filters, pointDeVente: selectedOptions || []});
            }
        };

        const isFormValid = filters.assureur !== '' && 
                   filters.pointDeVente.length > 0 && // Modifié ici
                   filters.dateRange.startDate && 
                   filters.dateRange.endDate;
    
        if (!show) return null;

        
    
        return (
            <div 
                className="fixed pb-60 inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                    resetFilters();
                }}
            >
                <div 
                    className="bg-white p-6 rounded-lg w-96"
                    onClick={(e) => e.stopPropagation()}
                >
                    <h2 className="text-xl font-bold mb-4 text-purple-700">Exporter les données</h2>
                    
                        <div className="space-y-4">
                        <div>
                            <label className="block mb-1 text-gray-700">Assureur</label>
                            <select
                                className="w-full px-4 py-2 rounded-lg border border-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
                                value={filters.assureur}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    setFilters({...filters, assureur: e.target.value});
                                }}
                            >
                                <option value="">Selectionnez un assureur</option>
                                {uniqueAssureurs.map((assureur, index) => (
                                    <option key={index} value={assureur}>
                                        {assureur}
                                    </option>
                                ))}
                            </select>
                        </div>
    
                        <div className='z-10'>
                            <label className="block mb-1 text-gray-700">Point de Vente</label>
                            <Select
                                isMulti
                                options={pdvOptions}
                                value={filters.pointDeVente}
                                onChange={handlePdvChange}
                                isOptionDisabled={(option) => 
                                    filters.pointDeVente?.some(selected => selected.value === "tous") && 
                                    option.value !== "tous"
                                }
                                placeholder="Sélectionnez les points de vente"
                                noOptionsMessage={() => "Aucun point de vente trouvé"}
                                className="w-full z-20"
                            />
                        </div>
    
                        <div>
                            <label className="block mb-1 text-gray-700">Période</label>
                                <Datepicker
                                    value={filters.dateRange}
                                    onChange={(newValue) => {
                                        setFilters({...filters, dateRange: newValue});
                                    }}
                                    showShortcuts={true}
                                    i18n={"fr"}
                                    popoverDirection='down'
                                    primaryColor="purple"
                                    displayFormat={"DD/MM/YYYY"}
                                    inputClassName="w-full px-4 py-2 rounded-lg border border-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
                                    placeholder="Sélectionnez une période"
                                />
                            </div>
                        </div>
    
                        <div className="flex justify-end gap-2 mt-6">
                            <button
                                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClose();
                                    resetFilters();
                                }}
                            >
                                Annuler
                            </button>
                            <button
                                className={`px-4 py-2 rounded ${
                                    isFormValid 
                                        ? 'bg-purple-600 hover:bg-purple-700 text-white cursor-pointer' 
                                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                }`}
                                disabled={!isFormValid}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (isFormValid) onExport();
                                }}
                            >
                                Exporter
                            </button>
                        </div>
                </div>
            </div>
        );
    };


    // const handleExport = async () => {
    //     try {
    //         // Validation des filtres
    //         if (!exportFilters.assureur || !exportFilters.pointDeVente.length || !exportFilters.dateRange.startDate || !exportFilters.dateRange.endDate) {
    //             alert("Veuillez remplir tous les champs requis");
    //             return;
    //         }
    
    //         const workbook = new ExcelJS.Workbook();
    //         const dateDebut = moment(exportFilters.dateRange.startDate).startOf('day');
    //         const dateFin = moment(exportFilters.dateRange.endDate).endOf('day');
            
    //         // Filtrage des données par assureur et date
    //         const filteredByAssureur = formattedData.filter(row => {
    //             const rowDate = moment(row.date, 'DD-MM-YYYY HH:mm');
    //             return row.assureur.nom === exportFilters.assureur &&
    //                    rowDate.isBetween(dateDebut, dateFin, null, '[]');
    //         });
    
    //         // Récupération des PDVs sélectionnés
    //         const pdvsToProcess = exportFilters.pointDeVente.map(pdv => pdv.value);
    
    //         if (pdvsToProcess.length === 0 || filteredByAssureur.length === 0) {
    //             alert("Aucune donnée à exporter pour les critères sélectionnés");
    //             return;
    //         }
    
    //         // Traitement pour chaque PDV
    //         for (const pdv of pdvsToProcess) {
    //             const filteredData = filteredByAssureur.filter(row => row.pdv === pdv);
    //             if (filteredData.length === 0) continue;
    
    //             // Création de la feuille Excel
    //             const safeSheetName = pdv.substring(0, 31).replace(/[\\*?:/[\]]/g, '');
    //             const worksheet = workbook.addWorksheet(safeSheetName);
    
    //             // Configuration des colonnes
    //             const columns = [
    //                 { header: 'Point de Vente', key: 'pointVente', width: 20 },
    //                 { header: 'Date de souscription', key: 'dateSouscription', width: 30 },
    //                 { header: 'N° de Police', key: 'numPolice', width: 15 },
    //                 { header: 'Souscripteur', key: 'souscripteur', width: 30 },
    //                 { header: 'Assuré(e)', key: 'assure', width: 30 },
    //                 { header: 'Date de Naissance', key: 'dateNaissance', width: 15 },
    //                 { header: 'Age', key: 'age', width: 10 },
    //                 { header: 'Destination', key: 'destination', width: 15 },
    //                 { header: 'N° de Passeport', key: 'numPasseport', width: 20 },
    //                 { header: 'Date de validité', key: 'dateValidite', width: 15 },
    //                 { header: 'Adresse', key: 'adresse', width: 15 },
    //                 { header: 'Date Effet', key: 'dateEffet', width: 15 },
    //                 { header: 'Date Echéance', key: 'dateEcheance', width: 15 },
    //                 { header: 'Durée du voyage', key: 'duree', width: 10 },
    //                 { header: 'Formule', key: 'formule', width: 20 },
    //                 { header: 'Prime Nette', key: 'primeNette', width: 15 },
    //                 { header: 'Accessoires', key: 'accessoires', width: 15 },
    //                 { header: 'Taxes', key: 'taxes', width: 15 },
    //                 { header: 'Frais de services', key: 'fraisServices', width: 20 },
    //                 { header: 'Prime TTC', key: 'primeTTC', width: 15 }
    //             ];
    //             worksheet.columns = columns;
    
    //             // En-tête avec titre et période
    //             worksheet.mergeCells('A1:T1');
    //             const titleCell = worksheet.getCell('A1');
    //             titleCell.value = `Détails de la production du point de vente ${pdv}`;
    //             titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
    //             titleCell.font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 14 };
    //             titleCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4F81BD' } };
    
    //             worksheet.mergeCells('A2:T2');
    //             const subtitleCell = worksheet.getCell('A2');
    //             subtitleCell.value = `Période: du ${moment(exportFilters.dateRange.startDate).format('DD/MM/YYYY')} au ${moment(exportFilters.dateRange.endDate).format('DD/MM/YYYY')}`;
    //             subtitleCell.alignment = { horizontal: 'center', vertical: 'middle' };
    //             subtitleCell.font = { bold: true, size: 12 };
    
    //             // Style des en-têtes de colonnes
    //             const headerRow = worksheet.getRow(3);
    //             columns.forEach((col, index) => {
    //                 const cell = headerRow.getCell(index + 1);
    //                 cell.value = col.header;
    //                 cell.font = { bold: true, size: 11 };
    //                 cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE0E6F8' } };
    //                 cell.border = {
    //                     top: { style: 'thin' },
    //                     left: { style: 'thin' },
    //                     bottom: { style: 'thin' },
    //                     right: { style: 'thin' }
    //                 };
    //                 cell.alignment = { horizontal: 'center', vertical: 'middle' };
    //             });

    //             const getDestinationName = (destinationId) => {
    //                 // Convertir l'id en string pour la comparaison
    //                 const stringId = destinationId.toString();
    //                 const pays = paysData.Pays.find(p => p.id === stringId);
    //                 return pays ? pays.nom : "Non définie";
    //             };
    //             // Ajout des données
    //             filteredData.forEach(contrat => {
    //                 contrat.voyageur.liste.forEach(voyageur => {
    //                     const age = moment().diff(moment(voyageur.dateNaissance), 'years');
    //                     const paysDestination = getDestinationName(contrat.voyage.destination);
    //                     const voy = `${voyageur.prenom} ${voyageur.nom}`;
    //                     const leContrat = contrat.modifc !== 0 ? `${contrat.policeNo}/${contrat.modifc}` : contrat.policeNo;
    //                     const newRow = worksheet.addRow({
    //                         pointVente: contrat.pdv,
    //                         dateSouscription: contrat.date,
    //                         numPolice: leContrat,
    //                         souscripteur: contrat.souscripteur.nom?.trim() || contrat.souscripteur.social || "Non défini",
    //                         assure: voy,
    //                         dateNaissance: moment(voyageur.dateNaissance).format("DD/MM/YYYY"),
    //                         age: age,
    //                         destination: paysDestination,
    //                         numPasseport: voyageur.numPassport,
    //                         dateValidite: moment(voyageur.dateExpiration).format("DD/MM/YYYY"),
    //                         adresse: voyageur.adresse,
    //                         dateEffet: moment(contrat.voyage.depart).format("DD/MM/YYYY"),
    //                         dateEcheance: moment(contrat.voyage.retour).format("DD/MM/YYYY"),
    //                         duree: contrat.voyage.duree,
    //                         formule: contrat.formule.libelle,
    //                         primeNette: Math.floor(contrat.decomposition.primeNette ),
    //                         accessoires: Math.floor(contrat.decomposition.accessoires),
    //                         taxes: Math.floor(contrat.decomposition.taxes),
    //                         fraisServices: Math.floor(contrat.decomposition.frais),
    //                         primeTTC: Math.floor(contrat.decomposition.primeTTC)
    //                     });
    
    //                     // Style des cellules
    //                     newRow.eachCell((cell, colNumber) => {
    //                         const columnKey = columns[colNumber - 1].key;
    //                         cell.border = {
    //                             top: { style: 'thin' },
    //                             left: { style: 'thin' },
    //                             bottom: { style: 'thin' },
    //                             right: { style: 'thin' }
    //                         };
                        
    //                         // Vérification des assurés ayant le même numéro de contrat
    //                         const assuresMemesContrat = filteredData.filter(item => {
    //                             return item.id === contrat.id && item.voyageur.liste.length > 1;
    //                         });
                        
    //                         const estAssureMultiple = assuresMemesContrat.length > 0;
                        
    //                         if (estAssureMultiple) {
    //                             if (contrat.modifc === 0) {
    //                                 // Contrat non modifié avec plusieurs assurés (vert)
    //                                 cell.fill = {
    //                                     type: 'pattern',
    //                                     pattern: 'solid',
    //                                     fgColor: { argb: '92D050' }
    //                                 };
    //                                 cell.font = { color: { argb: '000000' } };
    //                             } else {
    //                                 // Contrat modifié avec un seul assuré (rouge)
    //                                 cell.fill = {
    //                                     type: 'pattern',
    //                                     pattern: 'solid',
    //                                     fgColor: { argb: 'FB4141' }
    //                                 };
    //                                 cell.font = { color: { argb: '000000' } };
    //                             }
    //                         } else if (contrat.modifc !== 0) {
    //                                // Contrat modifié avec plusieurs assurés (orange)
    //                             cell.fill = {
    //                                 type: 'pattern',
    //                                 pattern: 'solid',
    //                                 fgColor: { argb: 'FCC737' }
    //                             };
    //                             cell.font = { color: { argb: '000000' } };
    //                         }
                        
    //                         // Alignement et format des nombres
    //                         if (['primeNette', 'accessoires', 'taxes', 'fraisServices', 'primeTTC'].includes(columnKey)) {
    //                             cell.alignment = { horizontal: 'right', vertical: 'middle' };
    //                             cell.numFmt = '#,##0';
    //                         } else {
    //                             cell.alignment = { horizontal: 'center', vertical: 'middle' };
    //                         }
    //                     });
    //                 });
    //             });

    //             worksheet.addRow({});
    //             // Ligne des totaux
    //             const totalRow = worksheet.addRow({
    //                 pointVente: 'Total',
    //                 dateSouscription: '',
    //                 numPolice: '',
    //                 souscripteur: '',
    //                 assure: '',
    //                 dateNaissance: '',
    //                 age: '',
    //                 destination: '',
    //                 numPasseport: '',
    //                 dateValidite: '',
    //                 adresse: '',
    //                 dateEffet: '',
    //                 dateEcheance: '',
    //                 duree: '',
    //                 formule: '',
    //                 primeNette: Math.floor(filteredData.reduce((sum, row) => sum + row.decomposition.primeNette, 0)),
    //                 accessoires: Math.floor(filteredData.reduce((sum, row) => sum + row.decomposition.accessoires, 0)),
    //                 taxes: Math.floor(filteredData.reduce((sum, row) => sum + row.decomposition.taxes, 0)),
    //                 fraisServices: Math.floor(filteredData.reduce((sum, row) => sum + row.decomposition.frais, 0)),
    //                 primeTTC: Math.floor(filteredData.reduce((sum, row) => sum + row.decomposition.primeTTC, 0))
    //             });
    
    //             // Style de la ligne des totaux
    //             totalRow.eachCell((cell, colNumber) => {
    //                 const columnKey = columns[colNumber - 1].key;
    //                 cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; 
    //                 cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '800080' } };
    //                 cell.border = {
    //                     top: { style: 'thin' },
    //                     left: { style: 'thin' },
    //                     bottom: { style: 'thin' },
    //                     right: { style: 'thin' }
    //                 };
                
    //                 if (['primeNette', 'accessoires', 'taxes', 'fraisServices', 'primeTTC'].includes(columnKey)) {
    //                     cell.alignment = { horizontal: 'right', vertical: 'middle' };
    //                     cell.numFmt = '#,##0';
    //                 } else {
    //                     cell.alignment = { horizontal: 'center', vertical: 'middle' };
    //                 }
    //             });

    //             worksheet.addRow({}); 

    //             // Ajout des légendes
    //             const legendRows = [
    //                 { description: 'Contrat groupé sans modification', color: '92D050' },
    //                 { description: 'Contrat groupé avec modification', color: 'FB4141' },
    //                 { description: 'Contrat modifié', color: 'FCC737' }
    //             ];

    //             // Création des lignes de légende
    //             legendRows.forEach(legend => {
    //                 const legendRow = worksheet.addRow(['', legend.description]);
                    
    //                 // Style de la cellule colorée (colonne A)
    //                 const colorCell = legendRow.getCell(1);
    //                 colorCell.fill = {
    //                     type: 'pattern',
    //                     pattern: 'solid',
    //                     fgColor: { argb: legend.color }
    //                 };
    //                 colorCell.border = {
    //                     top: { style: 'thin' },
    //                     left: { style: 'thin' },
    //                     bottom: { style: 'thin' },
    //                     right: { style: 'thin' }
    //                 };
    //                 colorCell.alignment = { horizontal: 'center', vertical: 'middle' };
                    
    //                 // Style de la description (colonne B)
    //                 const descCell = legendRow.getCell(2);
    //                 descCell.value = legend.description;
    //                 descCell.font = { bold: true };
    //                 descCell.alignment = { horizontal: 'left', vertical: 'middle' };
    //                 descCell.border = {
    //                     top: { style: 'thin' },
    //                     left: { style: 'thin' },
    //                     bottom: { style: 'thin' },
    //                     right: { style: 'thin' }
    //                 };
                    
    //                 // Ajuster la largeur des colonnes pour la légende
    //                 worksheet.getColumn(1).width = 20;
    //                 worksheet.getColumn(2).width = 30;
    //             });
    //         }
    
    //         // Génération et téléchargement du fichier
    //         const buffer = await workbook.xlsx.writeBuffer();
    //         const blob = new Blob([buffer], { 
    //             type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
    //         });
    //         const link = document.createElement('a');
    //         link.href = URL.createObjectURL(blob);
    //         link.download = `Du ${moment(exportFilters.dateRange.startDate).format('DD/MM/YYYY')} au ${moment(exportFilters.dateRange.endDate).format('DD/MM/YYYY')}.xlsx`;
    //         link.click();
    //         URL.revokeObjectURL(link.href);
    
    //         // Fermeture de la modale et réinitialisation des filtres
    //         setShowExportModal(false);
    //         resetFilters();
    //     } catch (error) {
    //         console.error('Erreur lors de l\'exportation:', error);
    //         alert('Une erreur est survenue lors de l\'exportation');
    //     }

    // };

    const handleExport = async () => {
        try {
            const getDestinationName = (destinationId) => {
                if (!destinationId) return "Non définie";
                const stringId = destinationId.toString();
                const pays = paysData.Pays.find(p => p.id === stringId);
                return pays ? pays.nom : "Non définie";
            };
                // 1. Validation des filtres
            if (!exportFilters.assureur || !exportFilters.pointDeVente.length || !exportFilters.dateRange.startDate || !exportFilters.dateRange.endDate) {
                alert("Veuillez remplir tous les champs requis");
                return;
            }

            const workbook = new ExcelJS.Workbook();
            const dateDebut = moment(exportFilters.dateRange.startDate).startOf('day');
            const dateFin = moment(exportFilters.dateRange.endDate).endOf('day');

            // 2. Ajout de logs pour débugger
            console.log("Filtres:", exportFilters);
            console.log("Points de vente:", exportFilters.pointDeVente);

            // 3. Filtrage des données par assureur et date
            const filteredByAssureur = formattedData.filter(row => {
                const rowDate = moment(row.date, 'DD-MM-YYYY HH:mm');
                return row.assureur.nom === exportFilters.assureur &&
                    rowDate.isBetween(dateDebut, dateFin, null, '[]');
            });

            console.log("Données filtrées:", filteredByAssureur);

            // 4. Vérification plus précise de l'option "tous"
            const isTousSelected = exportFilters.pointDeVente.some(pdv => pdv.value === "tous");
            const pdvsToProcess = isTousSelected 
                ? [...new Set(filteredByAssureur.map(row => row.pdv))] // Utiliser les PDVs des données filtrées
                : exportFilters.pointDeVente.map(pdv => pdv.value);

            console.log("PDVs à traiter:", pdvsToProcess);

            if (pdvsToProcess.length === 0 || filteredByAssureur.length === 0) {
                alert("Aucune donnée à exporter pour les critères sélectionnés");
                return;
            }
    
            if (isTousSelected) {
                // Cas où "Tous les points de vente" est sélectionné
                const worksheet = workbook.addWorksheet("Tous les PDVs");
    
                // Configuration des colonnes
                const columns = [
                    { header: 'Point de Vente', key: 'pointVente', width: 20 },
                    { header: 'Date de souscription', key: 'dateSouscription', width: 30 },
                    { header: 'N° de Police', key: 'numPolice', width: 15 },
                    { header: 'Souscripteur', key: 'souscripteur', width: 30 },
                    { header: 'Assuré(e)', key: 'assure', width: 30 },
                    { header: 'Date de Naissance', key: 'dateNaissance', width: 15 },
                    { header: 'Age', key: 'age', width: 10 },
                    { header: 'Destination', key: 'destination', width: 15 },
                    { header: 'N° de Passeport', key: 'numPasseport', width: 20 },
                    { header: 'Date de validité', key: 'dateValidite', width: 15 },
                    { header: 'Adresse', key: 'adresse', width: 15 },
                    { header: 'Date Effet', key: 'dateEffet', width: 15 },
                    { header: 'Date Echéance', key: 'dateEcheance', width: 15 },
                    { header: 'Durée du voyage', key: 'duree', width: 10 },
                    { header: 'Formule', key: 'formule', width: 20 },
                    { header: 'Prime Nette', key: 'primeNette', width: 15 },
                    { header: 'Accessoires', key: 'accessoires', width: 15 },
                    { header: 'Taxes', key: 'taxes', width: 15 },
                    { header: 'Frais de services', key: 'fraisServices', width: 20 },
                    { header: 'Prime TTC', key: 'primeTTC', width: 15 }
                ];
                worksheet.columns = columns;
    
                // En-tête avec titre et période
                worksheet.mergeCells('A1:T1');
                const titleCell = worksheet.getCell('A1');
                titleCell.value = `Détails de la production de tous les points de vente`;
                titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
                titleCell.font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 14 };
                titleCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4F81BD' } };

                // Sous-titre période (identique)
                worksheet.mergeCells('A2:T2');
                const subtitleCell = worksheet.getCell('A2');
                subtitleCell.value = `Période: du ${moment(exportFilters.dateRange.startDate).format('DD/MM/YYYY')} au ${moment(exportFilters.dateRange.endDate).format('DD/MM/YYYY')}`;
                subtitleCell.alignment = { horizontal: 'center', vertical: 'middle' };
                subtitleCell.font = { bold: true, size: 12 };

                // Style des en-têtes (identique)
                const headerRow = worksheet.getRow(3);
                columns.forEach((col, index) => {
                    const cell = headerRow.getCell(index + 1);
                    cell.value = col.header;
                    cell.font = { bold: true, size: 11 };
                    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE0E6F8' } };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                    cell.alignment = { horizontal: 'center', vertical: 'middle' };
                });
    
                // Ajout des données pour tous les PDVs
                filteredByAssureur.forEach(contrat => {
                    contrat.voyageur.liste.forEach(voyageur => {
                        const age = moment().diff(moment(voyageur.dateNaissance), 'years');
                        const paysDestination = getDestinationName(contrat.voyage.destination);
                        const voy = `${voyageur.prenom} ${voyageur.nom}`;
                        const leContrat = contrat.modifc !== 0 ? `${contrat.policeNo}/${contrat.modifc}` : contrat.policeNo;
                        const newRow = worksheet.addRow({
                            pointVente: contrat.pdv,
                            dateSouscription: contrat.date,
                            numPolice: leContrat,
                            souscripteur: contrat.souscripteur.nom?.trim() || contrat.souscripteur.social || "Non défini",
                            assure: voy,
                            dateNaissance: moment(voyageur.dateNaissance).format("DD/MM/YYYY"),
                            age: age,
                            destination: paysDestination,
                            numPasseport: voyageur.numPassport,
                            dateValidite: moment(voyageur.dateExpiration).format("DD/MM/YYYY"),
                            adresse: voyageur.adresse,
                            dateEffet: moment(contrat.voyage.depart).format("DD/MM/YYYY"),
                            dateEcheance: moment(contrat.voyage.retour).format("DD/MM/YYYY"),
                            duree: contrat.voyage.duree,
                            formule: contrat.formule.libelle,
                            primeNette: Math.floor(contrat.decomposition.primeNette),
                            accessoires: Math.floor(contrat.decomposition.accessoires),
                            taxes: Math.floor(contrat.decomposition.taxes),
                            fraisServices: Math.floor(contrat.decomposition.frais),
                            primeTTC: Math.floor(contrat.decomposition.primeTTC)
                        });
            
                        // Appliquer le même style aux cellules
                        newRow.eachCell((cell, colNumber) => {
                            const columnKey = columns[colNumber - 1].key;
                            cell.border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };
            
                            // Même logique de coloration
                            const assuresMemesContrat = filteredByAssureur.filter(item => {
                                return item.id === contrat.id && item.voyageur.liste.length > 1;
                            });
            
                            const estAssureMultiple = assuresMemesContrat.length > 0;
            
                            if (estAssureMultiple) {
                                if (contrat.modifc === 0) {
                                    cell.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: '92D050' }
                                    };
                                } else {
                                    cell.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FB4141' }
                                    };
                                }
                            } else if (contrat.modifc !== 0) {
                                cell.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FCC737' }
                                };
                            }
            
                            // Même formatage des nombres
                            if (['primeNette', 'accessoires', 'taxes', 'fraisServices', 'primeTTC'].includes(columnKey)) {
                                cell.alignment = { horizontal: 'right', vertical: 'middle' };
                                cell.numFmt = '#,##0';
                            } else {
                                cell.alignment = { horizontal: 'center', vertical: 'middle' };
                            }
                        });
                    });
                });

                // Ajout de la ligne vide
                worksheet.addRow({});



                // Ligne des totaux (identique)
                const totalRow = worksheet.addRow({
                    pointVente: 'Total',
                    dateSouscription: '',
                    numPolice: '',
                    souscripteur: '',
                    assure: '',
                    dateNaissance: '',
                    age: '',
                    destination: '',
                    numPasseport: '',
                    dateValidite: '',
                    adresse: '',
                    dateEffet: '',
                    dateEcheance: '',
                    duree: '',
                    formule: '',
                    primeNette: Math.floor(filteredByAssureur.reduce((sum, row) => sum + row.decomposition.primeNette, 0)),
                    accessoires: Math.floor(filteredByAssureur.reduce((sum, row) => sum + row.decomposition.accessoires, 0)),
                    taxes: Math.floor(filteredByAssureur.reduce((sum, row) => sum + row.decomposition.taxes, 0)),
                    fraisServices: Math.floor(filteredByAssureur.reduce((sum, row) => sum + row.decomposition.frais, 0)),
                    primeTTC: Math.floor(filteredByAssureur.reduce((sum, row) => sum + row.decomposition.primeTTC, 0))
                });

                // Style de la ligne des totaux (identique)
                totalRow.eachCell((cell, colNumber) => {
                    const columnKey = columns[colNumber - 1].key;
                    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '800080' } };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };

                    if (['primeNette', 'accessoires', 'taxes', 'fraisServices', 'primeTTC'].includes(columnKey)) {
                        cell.alignment = { horizontal: 'right', vertical: 'middle' };
                        cell.numFmt = '#,##0';
                    } else {
                        cell.alignment = { horizontal: 'center', vertical: 'middle' };
                    }
                });

                // Ajouter la légende (identique)
                worksheet.addRow({});
                const legendRows = [
                    { description: 'Contrat groupé sans modification', color: '92D050' },
                    { description: 'Contrat groupé avec modification', color: 'FB4141' },
                    { description: 'Contrat modifié', color: 'FCC737' }
                ];

                legendRows.forEach(legend => {
                    const legendRow = worksheet.addRow(['', legend.description]);
                    
                    // Style de la cellule colorée (colonne A)
                    const colorCell = legendRow.getCell(1);
                    colorCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: legend.color }
                    };
                    colorCell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                    colorCell.alignment = { horizontal: 'center', vertical: 'middle' };
                    
                    // Style de la description (colonne B)
                    const descCell = legendRow.getCell(2);
                    descCell.value = legend.description;
                    descCell.font = { bold: true };
                    descCell.alignment = { horizontal: 'left', vertical: 'middle' };
                    descCell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                    
                    // Ajuster la largeur des colonnes pour la légende
                    worksheet.getColumn(1).width = 20;
                    worksheet.getColumn(2).width = 30;
                });

            } else {
                // Cas par point de vente (comme dans la configuration existante)
                for (const pdv of pdvsToProcess) {
                    const filteredData = filteredByAssureur.filter(row => row.pdv === pdv);
                    if (filteredData.length === 0) continue;
    
                    const safeSheetName = pdv.substring(0, 31).replace(/[\\*?:/[\]]/g, '');
                    const worksheet = workbook.addWorksheet(safeSheetName);
    
                    // Configuration des colonnes
                    const columns = [
                        { header: 'Point de Vente', key: 'pointVente', width: 20 },
                        { header: 'Date de souscription', key: 'dateSouscription', width: 30 },
                        { header: 'N° de Police', key: 'numPolice', width: 15 },
                        { header: 'Souscripteur', key: 'souscripteur', width: 30 },
                        { header: 'Assuré(e)', key: 'assure', width: 30 },
                        { header: 'Date de Naissance', key: 'dateNaissance', width: 15 },
                        { header: 'Age', key: 'age', width: 10 },
                        { header: 'Destination', key: 'destination', width: 15 },
                        { header: 'N° de Passeport', key: 'numPasseport', width: 20 },
                        { header: 'Date de validité', key: 'dateValidite', width: 15 },
                        { header: 'Adresse', key: 'adresse', width: 15 },
                        { header: 'Date Effet', key: 'dateEffet', width: 15 },
                        { header: 'Date Echéance', key: 'dateEcheance', width: 15 },
                        { header: 'Durée du voyage', key: 'duree', width: 10 },
                        { header: 'Formule', key: 'formule', width: 20 },
                        { header: 'Prime Nette', key: 'primeNette', width: 15 },
                        { header: 'Accessoires', key: 'accessoires', width: 15 },
                        { header: 'Taxes', key: 'taxes', width: 15 },
                        { header: 'Frais de services', key: 'fraisServices', width: 20 },
                        { header: 'Prime TTC', key: 'primeTTC', width: 15 }
                    ];
                    worksheet.columns = columns;
        
                    // En-tête avec titre et période
                    worksheet.mergeCells('A1:T1');
                    const titleCell = worksheet.getCell('A1');
                    titleCell.value = `Détails de la production du point de vente ${pdv}`;
                    titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
                    titleCell.font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 14 };
                    titleCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4F81BD' } };
        
                    worksheet.mergeCells('A2:T2');
                    const subtitleCell = worksheet.getCell('A2');
                    subtitleCell.value = `Période: du ${moment(exportFilters.dateRange.startDate).format('DD/MM/YYYY')} au ${moment(exportFilters.dateRange.endDate).format('DD/MM/YYYY')}`;
                    subtitleCell.alignment = { horizontal: 'center', vertical: 'middle' };
                    subtitleCell.font = { bold: true, size: 12 };
        
                    // Style des en-têtes de colonnes
                    const headerRow = worksheet.getRow(3);
                    columns.forEach((col, index) => {
                        const cell = headerRow.getCell(index + 1);
                        cell.value = col.header;
                        cell.font = { bold: true, size: 11 };
                        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE0E6F8' } };
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                        cell.alignment = { horizontal: 'center', vertical: 'middle' };
                    });
                    // Ajout des données
                    filteredData.forEach(contrat => {
                        contrat.voyageur.liste.forEach(voyageur => {
                            const age = moment().diff(moment(voyageur.dateNaissance), 'years');
                            const paysDestination = getDestinationName(contrat.voyage.destination);
                            const voy = `${voyageur.prenom} ${voyageur.nom}`;
                            const leContrat = contrat.modifc !== 0 ? `${contrat.policeNo}/${contrat.modifc}` : contrat.policeNo;
                            const newRow = worksheet.addRow({
                                pointVente: contrat.pdv,
                                dateSouscription: contrat.date,
                                numPolice: leContrat,
                                souscripteur: contrat.souscripteur.nom?.trim() || contrat.souscripteur.social || "Non défini",
                                assure: voy,
                                dateNaissance: moment(voyageur.dateNaissance).format("DD/MM/YYYY"),
                                age: age,
                                destination: paysDestination,
                                numPasseport: voyageur.numPassport,
                                dateValidite: moment(voyageur.dateExpiration).format("DD/MM/YYYY"),
                                adresse: voyageur.adresse,
                                dateEffet: moment(contrat.voyage.depart).format("DD/MM/YYYY"),
                                dateEcheance: moment(contrat.voyage.retour).format("DD/MM/YYYY"),
                                duree: contrat.voyage.duree,
                                formule: contrat.formule.libelle,
                                primeNette: Math.floor(contrat.decomposition.primeNette ),
                                accessoires: Math.floor(contrat.decomposition.accessoires),
                                taxes: Math.floor(contrat.decomposition.taxes),
                                fraisServices: Math.floor(contrat.decomposition.frais),
                                primeTTC: Math.floor(contrat.decomposition.primeTTC)
                            });
        
                            // Style des cellules
                            newRow.eachCell((cell, colNumber) => {
                                const columnKey = columns[colNumber - 1].key;
                                cell.border = {
                                    top: { style: 'thin' },
                                    left: { style: 'thin' },
                                    bottom: { style: 'thin' },
                                    right: { style: 'thin' }
                                };
                            
                                // Vérification des assurés ayant le même numéro de contrat
                                const assuresMemesContrat = filteredData.filter(item => {
                                    return item.id === contrat.id && item.voyageur.liste.length > 1;
                                });
                            
                                const estAssureMultiple = assuresMemesContrat.length > 0;
                            
                                if (estAssureMultiple) {
                                    if (contrat.modifc === 0) {
                                        // Contrat non modifié avec plusieurs assurés (vert)
                                        cell.fill = {
                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: '92D050' }
                                        };
                                        cell.font = { color: { argb: '000000' } };
                                    } else {
                                        // Contrat modifié avec un seul assuré (rouge)
                                        cell.fill = {
                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FB4141' }
                                        };
                                        cell.font = { color: { argb: '000000' } };
                                    }
                                } else if (contrat.modifc !== 0) {
                                    // Contrat modifié avec plusieurs assurés (orange)
                                    cell.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FCC737' }
                                    };
                                    cell.font = { color: { argb: '000000' } };
                                }
                            
                                // Alignement et format des nombres
                                if (['primeNette', 'accessoires', 'taxes', 'fraisServices', 'primeTTC'].includes(columnKey)) {
                                    cell.alignment = { horizontal: 'right', vertical: 'middle' };
                                    cell.numFmt = '#,##0';
                                } else {
                                    cell.alignment = { horizontal: 'center', vertical: 'middle' };
                                }
                            });
                        });
                    });

                    worksheet.addRow({});
                    // Ligne des totaux
                    const totalRow = worksheet.addRow({
                        pointVente: 'Total',
                        dateSouscription: '',
                        numPolice: '',
                        souscripteur: '',
                        assure: '',
                        dateNaissance: '',
                        age: '',
                        destination: '',
                        numPasseport: '',
                        dateValidite: '',
                        adresse: '',
                        dateEffet: '',
                        dateEcheance: '',
                        duree: '',
                        formule: '',
                        primeNette: Math.floor(filteredData.reduce((sum, row) => sum + row.decomposition.primeNette, 0)),
                        accessoires: Math.floor(filteredData.reduce((sum, row) => sum + row.decomposition.accessoires, 0)),
                        taxes: Math.floor(filteredData.reduce((sum, row) => sum + row.decomposition.taxes, 0)),
                        fraisServices: Math.floor(filteredData.reduce((sum, row) => sum + row.decomposition.frais, 0)),
                        primeTTC: Math.floor(filteredData.reduce((sum, row) => sum + row.decomposition.primeTTC, 0))
                    });
        
                    // Style de la ligne des totaux
                    totalRow.eachCell((cell, colNumber) => {
                        const columnKey = columns[colNumber - 1].key;
                        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; 
                        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '800080' } };
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                    
                        if (['primeNette', 'accessoires', 'taxes', 'fraisServices', 'primeTTC'].includes(columnKey)) {
                            cell.alignment = { horizontal: 'right', vertical: 'middle' };
                            cell.numFmt = '#,##0';
                        } else {
                            cell.alignment = { horizontal: 'center', vertical: 'middle' };
                        }
                    });

                    worksheet.addRow({}); 

                    // Ajout des légendes
                    const legendRows = [
                        { description: 'Contrat groupé sans modification', color: '92D050' },
                        { description: 'Contrat groupé avec modification', color: 'FB4141' },
                        { description: 'Contrat modifié', color: 'FCC737' }
                    ];

                    // Création des lignes de légende
                    legendRows.forEach(legend => {
                        const legendRow = worksheet.addRow(['', legend.description]);
                        
                        // Style de la cellule colorée (colonne A)
                        const colorCell = legendRow.getCell(1);
                        colorCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: legend.color }
                        };
                        colorCell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                        colorCell.alignment = { horizontal: 'center', vertical: 'middle' };
                        
                        // Style de la description (colonne B)
                        const descCell = legendRow.getCell(2);
                        descCell.value = legend.description;
                        descCell.font = { bold: true };
                        descCell.alignment = { horizontal: 'left', vertical: 'middle' };
                        descCell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                        
                        // Ajuster la largeur des colonnes pour la légende
                        worksheet.getColumn(1).width = 20;
                        worksheet.getColumn(2).width = 30;
                    });
                }
            }
    
            // Génération et téléchargement du fichier
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { 
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Export-${moment().format('DD-MM-YYYY')}.xlsx`;
            link.click();
            URL.revokeObjectURL(link.href);
    
            // Fermeture de la modale et réinitialisation des filtres
            setShowExportModal(false);
            resetFilters();
        } catch (error) {
            console.error('Erreur lors de l\'exportation:', error);
            alert('Une erreur est survenue lors de l\'exportation');
        }
    };
    
    return (
        <div className='flex h-screen'>
            <Sidebar isOpen={isOpen} onToggle={() => setIsOpen(!isOpen)} />
            <div className={`flex-1 transition-all duration-500 ${isOpen ? 'ml-[16rem]' : 'ml-[5rem]'}`}>
                <HomeNavbar isOpen={isOpen} />
                <main className="p-6 mt-16 h-screen">
                    <AdminUserBanner />
                    <div className="flex justify-between items-center mb-4">
                        <div className='flex flex-col md:flex-row items-center gap-4'>
                            <h2 className="text-xl font-bold text-white bg-purple-700 px-4 py-2 rounded-md whitespace-nowrap">
                                Liste des Contrats voyage
                                <span className="ml-3">({numData || 0})</span>
                            </h2>
                            {userDetails.role !== "Vendeur" && (
                                <>
                                    <div className="w-full sm:w-[200px] md:w-[220px] lg:w-[250px] xl:w-[300px]">
                                    <SelectDistributeurs 
                                        distributeurs={lesDistributeurs} 
                                        onSelect={(distributeur) => {
                                            setRowData([]); // Vider les données
                                            setNumData(0); // Réinitialiser le compteur
                                            setIsLoading(true); // Activer le loader
                                            setSelectedDistributeur(distributeur.id); // Mettre à jour le distributeur sélectionné
                                        }}
                                        isLoading={isLoading || isLoadingDistributeurs}
                                        disabled={isLoading} 
                                    />
                                    </div>
                                </>
                            )}
                        </div>

                        <div className='flex gap-2 items-center'>
                            {userDetails.role !== "Vendeur" && (
                                <button
                                    onClick={() => setShowExportModal(true)}
                                    className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors flex items-center gap-2"
                                >
                                    <PiMicrosoftExcelLogoFill className="w-5 h-5" />
                                    Exporter
                                </button>
                            )}
                            
                            <div className='relative w-full sm:w-[200px] md:w-[220px] lg:w-[250px] xl:w-[300px]'>
                                <input
                                    type="text"
                                    placeholder="Rechercher un contrat..."
                                    className="w-full px-4 py-2 rounded-lg border border-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                    {/* Ajouter la modale d'exportation */}
                    <ExportModal
                        show={showExportModal}
                        onClose={() => setShowExportModal(false)}
                        onExport={handleExport}
                        filters={exportFilters}
                        setFilters={setExportFilters}
                        formattedData={formattedData} // Passez l'état formattedData ici
                    />

                    <motion.div
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
                        exit={{ y: 100, opacity: 0 }}
                        className='border rounded-lg '
                    >
                        <VendeursContratDatatable data={filteredData} chargement={isLoading}  />
                    </motion.div>
                </main>
            </div>
        </div>
    );
};


export default ContratsVoyageList;

